.footer {
  /* height: 365px; */
  position: relative;
  width: 100%;
  background: url("../../../public/images/footer.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.contacts {
  display: none;
}
.cont{
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.parts {
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin:  auto;
  /* position: absolute;

  left: 330px;
  top: 83px; */
  align-items: center;
  padding: 80px 0;
}

.parts .pages ul li {
  list-style-type: none;
  font-size: 18px;
  color: white;
  line-height: 31px;
  padding-bottom: 5px;
  text-transform:lowercase;
  
}
.pages ul li a {
  
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.pages ul li::first-letter{
  text-transform: uppercase;
}
.pages ul li a:after {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.5s;
}
.pages ul li a:hover::after {
  width: 100%;
}

.parts .contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 250px;
}
.parts .contact .icon {
  display: flex;
  align-items: center;
  color: white;
}
.parts .contact .icon  svg{
 font-size: 22px;
}
.parts .contact .icon p {
  margin: 7px;
  line-height: 21px;
  font-size: 16px;
}
.parts .contact .icon a {
  margin: 7px;
  line-height: 21px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.parts .follow h3 {
  padding: 0 0 24px 0;
  color: white;
  font-weight: 400;
  font-size: 18px;
}
.parts .follow a {
  color: white;
  font-size: 25px;
  margin-right: 10px;
}
.parts .contact .icon a{
  text-decoration: none;
}
.contacts {
  display: none;
}

.footerContainer {
  text-align: center;
  /* border-top: 1px solid #fdfdfd; */
  /* margin-top: 80px; */
  position: relative;
}
.footerContainer .copyright {
  color: #fdfdfd;
 padding: 20px;
}

@media screen and (max-width: 1300px) {
 
  .parts .contact {
    margin: 0;
  }
}



@media screen and (max-width: 760px) {

  .parts .pages ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0;
  }
  .contact p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
  }
  .parts .follow h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 578px) {
  
  /* .footer {
    height: 200px;
  } */
  .parts{
     display: none;
  }

  .parts .pages ul {
    display: none;
  }
  .parts .contact {
    display: none;
  }
  .follow {
    display: none;
  }
  .contacts {
    position: relative;
   padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .icon12 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .icon1 {
    align-items: center;
    display: flex;
    font-size: 23px;
   
  }
  .icon1 p, .icon1 a {
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-left: 5px;
  }
  .icon2 {
    align-items: center;
    display: flex;
    font-size: 23px;
   
  }
  .icon2 p, .icon2 a {
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
    line-height: 19px;
  }
  .icon3 {
    align-items: center;
    gap: 10px;
    display: flex;
    flex-direction: column;
  
  }
  .icon3 p, .icon3 a {
    width: 180px;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 20px 0;
  }
  .icon3 a{
    font-size: 23px;
  }
  .icon3 svg{
    font-size: 23px;
  }

  .flw {
    display: flex;
    gap: 10px;
    max-width: 100px;
  }
  .flw a {
    color: white;
  }
}
@media screen and (max-width: 452px) {
  .footerContainer .copyright {
    left: 85px;
    top: 165px;
    font-size: 10px;
  }
}
@media screen and (max-width: 378px) {
  .icon1 {
    left: 5px;
  }
  .icon1 p , .icon1 a{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
  }
  .icon2 p , .icon2 a{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
  }

  .icon3 {
    left: 89px;
    gap: 5px;
  }
  .icon3 p, .icon3 a {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 19px;
  }
  .footerContainer .copyright {
    left: 65px;
    top: 165px;
    font-size: 10px;
  }
  .cont{
    padding-left: 5px;
    padding-right: 5px;
  }
  .flw a{
    font-size: 15px;
  }
}


@media screen and (max-width: 375px) {
  .icon12 {
    width: 80%;
  }
  .icon1 p, .icon1 a {
    font-size: 14px;    
}
.icon2 p, .icon2 a {
  font-size: 14px;

}
.icon3 p, .icon3 a {
  font-size: 14px;
}
.footerContainer .copyright {

  font-size: 12px;
}

}


@media screen and (max-width: 340px){
  .footerContainer .copyright {
    left: 40px;
  }


}
