.navbar {
  display: flex;
  width: 100%;
  background-color: #1e4078;
  height: 64px;
  align-items: center;
  overflow: hidden;
}

.nav {
  width: 80%;
  /* margin: 0 0 0 20%; */
}
.contnav{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
}
.nav ul {
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
}
.nav ul li {
  list-style-type: none;
  margin-right: 40px;
}
.nav ul li a {
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.languages ul img {
  margin: 10px;
}
.nav ul li a:after {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.5s;
}
.nav ul li a:hover::after {
  width: 100%;
}

.languages {
  max-width: 20%;
  width: 100%;
  margin: 0 auto;
}
.languages ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.languages ul .lang-li {
  color: #1B7F9D;
  
  cursor: pointer;
}
.lang-li-active{
  color: #fff;
  
}
.languages ul svg {
  margin: 10px;
}
.languages ul svg.lang-li-active circle {
  fill: #fff;
}
@media screen and (max-width: 767px) {
  /* .hamburger{
  display: block !important;
} */
  .languages {
    display: block;
  }
}
@media screen and (max-width: 1007px) {
  .languages {
    display: none;
  }
  .nav ul {
    /* justify-content: space-between; */
    margin-right: unset;
    justify-content: center;
  }
  .nav ul li a {
   
    font-size: 16px;
  }
  .nav ul li  {
   
   margin-right: 15px;
  }
  .nav {
    margin: 0 auto;
    width: 100%;
  }
}

#toggle {
  display: none;
}
@media screen and (max-width: 579px) {
  .navbar {
    display: none;
  }
}

/* .hamburger {
  position: absolute;
  top: 5em;
  right: 5%;
  margin-left: -2em;
  margin-top: -45px;
  width: 2em;
  height: 45px;
  z-index: 5;
  display: none;
} */

/* .hamburger div {
  position: relative;
  width: 3em;
  height: 7px;
  border-radius: 3px;
  background-color: #1E4078;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
} */

/**
Nav Styles
**/
.nav-media {
  position: fixed;
  width: 30%;
  height: 30%;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
}
.nav-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
.nav-media {
  text-align: left;
  margin-left: 35%;
}
.nav-media a {
  position: relative;
  text-decoration: none;
  color: #6ecddd;
  font-size: 1em;
  display: inline-block;
  margin-top: 1.25em;
  transition: color 0.2s ease-in-out;
  letter-spacing: 1px;
}
.nav-media a:before {
  content: "";
  height: 0;
  position: absolute;
  width: 0.25em;
  background-color: white;
  left: -0.5em;
  transition: all 0.2s ease-in-out;
}
.nav-media a:hover {
  color: white;
}
.nav-media a:hover:before {
  height: 100%;
}

/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -7px;
}

#toggle:checked + .hamburger + .nav-media {
  top: 0;
  transform: scale(1);
}
