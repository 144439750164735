@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.main {
  background-image: url("../../../public/images/cover.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* max-width: 1440px; */
  /* height: 515px; */
  overflow: hidden;
  padding: 50px auto;
}

@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}

@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, 
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
  width: 14px;
  height: 14px;
  /* border: 1px solid #1E4078; */
  border: 1px solid #ffffff !important;

}
.swiper-pagination-bullet-active{
  /* background: #1E4078!important;
  border: 1px solid #1E4078!important; */
  background: #ffffff!important;
  border: 1px solid #ffffff!important;
}
.swiper-pagination-bullet{
  /* border: 1px solid #1E4078!important; */
  border: 1px solid #ffffff!important;
  background: transparent;
  opacity: 1;
}
h1 {
  font-family: websoft_title;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
  text-transform: capitalize;
  color: #1E4078;
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);

}

.icon-fix-box a{color: #000; cursor: pointer;}
.icon-fix-box a:hover{color: #201f1f; cursor: pointer;}

.btn a{cursor: pointer;}

@media screen and (max-width:415px) {
  .all {
    height: 322px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .header {
    position: absolute !important;
    z-index: 100;
    left: 40px !important;
  }

  .main .picture1 {

    z-index: -1;
    width: 80% !important;
    height: 80%;
    margin: 0 !important;
  }

  h1 {
    font-size: 16px !important;
    width: 200px !important;
    line-height: 20px !important;

  }
  .main{background-image: none;}
  .main .header .header_button .btn {
    
    height: 36px !important;
  }
  .icon-fix-cont {
    width: 150px !important;
    height: 175px !important;
  }
  .icon-fix-img-info {
    font-size: 13px !important;
    margin-left: 10px !important;
}
.icon-fix-box {
  margin-left: 10px !important;
}
.icon-fix {
  width: 45px !important;
  height: 45px !important;
}
.icon-fix-cont{
  right: 20px !important;
  bottom: 50px !important;
}

}

@media screen and (max-width:800px) {
  h1 {
    font-size: 25px !important;
    max-width: 160px !important;
    line-height: 40px !important;
  }

  .main .text-section {
    max-width: 300px !important;
  }

  .picture1 {
    margin-top: 30px !important;
    margin-right: 30px !important;
  }

  .main .header .header_button .btn {
    width: 150px !important;
    font-size: 15px !important;

  }

  .main .header {
    gap: 20px !important;
  }

}

@media screen and (max-width:620px) {
  h1 {
    font-size: 22px !important;
    max-width: 140px !important;
    line-height: 30px !important;
  }

  .main .header .header_button .btn {
    width: 110px !important;
    padding: 5px;
    font-size: 12px !important;

  }

  .main .header {
    gap: 15px !important;
  }
}

@media screen and (max-width:550px) {
  h1 {
    max-width: 120px !important;
    font-size: 18px !important;
  }

  .main .header {
    gap: 5px !important;
    line-height: 30px !important;
  }
}

@media screen and (max-width:420px) {
  h1 {
    font-size: 14px !important;
    line-height: 20px !important;

  }

  .main .text-section {
    max-width: 160px !important;
    background: rgba(250,250,250,0.5);
    border-radius: 10px;
    text-align: center;
  }

  .main .header {
    gap: 5px !important;
    line-height: 30px !important;
  }
}

@media screen and (max-width:480px) {
  h1 {
    max-width: 100px !important;
    font-size: 16px !important;
  }

  .main .header {
    gap: 5px !important;
    line-height: 30px !important;
  }
}

@media screen and (max-width:450px) {
  h1 {
    font-size: 19px !important;
  }

  .main .header {
    gap: 5px !important;
    line-height: 30px !important;
  }

  .main .header .header_button .btn{height: 35px !important;}


}

.main .header {
  width: 50%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  position: relative;
}

.main .header .header_button {
  outline: none;
  color: #1e4078;
  border: none;
}

.main .header .header_button .btn {
  background-color: #1e4078;
  border: none;
  width: 200px;
  height: 45px;
  font-size: 18px;
  color: white;
  font-weight: 600;
  border-radius: 3px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: websoft_text;
}

.header_button .btn:hover {
  background: linear-gradient(90deg, #1c4787 -2.41%, #429eb9 46%, #1b7f9d 85.62%, #1b7f9d 80.4%, #1b7f9d 96.28%);
}

.main .picture1 {
  position: relative;
  z-index: 1;
  margin-top: 50px;
  /* margin-right: 56px; */
  width: 550px;
  margin-bottom: 45px;
  width: 40%;
  margin-left: 30px;


}

.main .text-section {
  max-width: 400px;
}

.main .text-section h1 {
  max-width: 310px;
  margin: 0;
  font-size: 48px;
  color: #1e4078;
  text-align: center;
  font-weight: 400;
  font-family: websoft_title;
}

.all {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;

}

.vertical {
  height: 42%;
  position: absolute;
  left: -6%;
  top: 0px;
}

.horizontal {
  width: 33%;
  position: absolute;
  right: 0px;
  bottom: 0%;
}

.icon-fix {
  width: 50px;
  height: 50px;
  background-color: #EAFEFF;
  border-radius: 50%;
  border: 7px solid #EAFEFF;
  position: fixed;
  right: 10px;
  bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: icon-fix 2s infinite;
}
.icon-fix-not{
  display: none;
}
.icon-fix:hover {
  animation: none;
}

.icon-fix-1 {
  width: 20px;
  height: 20px;
}



@media screen and (max-width:4px) {
  .all {
    height: 322px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .header {
    position: absolute !important;
    z-index: 100;
    left: 40px !important;
  }

  .main .picture1 {

    z-index: -1;
    width: 80% !important;
    height: 80%;
    margin: 0 !important;
  }

  h1 {
    font-size: 16px !important;
    width: 200px !important;
    line-height: 20px !important;

  }
  .main{background-image: none;}
  .main .header .header_button .btn {
    height: 36px !important;
  }
  .icon-fix-cont {
    width: 150px !important;
    height: 175px !important;
  }
  .icon-fix-img-info {
    font-size: 13px !important;
    margin-left: 10px !important;
}
.icon-fix-box {
  margin-left: 10px !important;
}
.icon-fix {
  width: 45px !important;
  height: 45px !important;
}
.icon-fix-cont{
  right: 20px !important;
  bottom: 50px !important;
}

}






@-webkit-keyframes icon-fix {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(28, 92, 129, 0.4);
   
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(49, 17, 163, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(25, 47, 83, 0);
  }
}

@keyframes icon-fix {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(16, 24, 106, 0.4);
    box-shadow: 0 0 0 0 rgba(16, 60, 88, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}


.icon-fix-cont {
  width: 200px;
  height: 180px;
  background-color: #deeaee;
  position: absolute;
  right: 10px;
  bottom: 53px;
  border-radius: 4px;
}
.icon-fix-img{
  width: 30px;
}

.icon-fix-img-info{
  font-size: 15px;
  font-weight: 600;
  opacity: 0.9;
  font-family: 'websoft_text';
  margin-left: 20px;
}
.icon-fix-box{
  display: flex;
  align-items: center;
  padding: 6px;
}


@media(max-width: 1024px ){
  .horizontal{
    bottom: 1%;
  }
}  


@media(max-width: 1000px ){
  .horizontal{
    bottom: 5%;
  }
  .mySwiper-4 .swiper-pagination{
    margin-top: 0px;
  
    
    }
    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
      width: 10px;
      height: 10px;
    }

}
@media(max-width: 700px ){
  .horizontal{
    bottom: 8%;
  }
  .mySwiper-4 .swiper-pagination{
    margin-top: -5px;
  
    
    }

}
@media(max-width: 576px ){
  .all{
    padding-left: 5px;
    padding-right: 5px;
  }
  .horizontal{
    bottom: 10%;
  }
  .mySwiper-4 .swiper-pagination{
    margin-top: -17px;
  
    
    }
    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
      width: 8px;
      height: 8px;
    }

}




@media screen and (max-width:425px) {
  .main{
    background: none;
    margin-top: -20px;
  }
  .all {
    height: 360px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: -15px;
  }

  .header {
    position: absolute !important;
    z-index: 100;
    left: 40px !important;
  }
  .main .text-section {
    max-width: 160px !important;
    background: rgba(250,250,250,0.5);
    border-radius: 10px;
    text-align: center;
  }

  .main .header .header_button .btn {
    width: 110px !important;
    padding: 5px;
    font-size: 12px !important;
}

  .main .picture1 {

    z-index: -1;
    width: 85% !important;
    height: 80%;
    margin: 0 0 0 15px !important;
  }

  h1 {
    font-size: 20px !important;
    max-width: 140px!important;
    width: 100% !important;
    line-height: 24px !important;

  }
  .horizontal {
    bottom: 2%;
    left: 67%;
}
  .mySwiper-4 .swiper-pagination{
    margin-top: 11px;
    
    }
    /* Dot  */
    .swiper-pagination-bullet{
      border: 1px solid #fff !important;
    }
    .swiper-pagination-bullet-active{
      background: #ffffff !important;
    }


}














@media screen and (max-width:400px) {
  .horizontal{bottom: 3% !important ;}
  .mySwiper-4 .swiper-pagination{margin-top: 7px !important;}
}


@media screen and (max-width:414px) {
  .horizontal{
    bottom: 2% !important;
    left: 63%;
  }
  .mySwiper-4 .swiper-pagination{margin-top: 9px !important;}
}


@media screen and (max-width:415px) {
  .all {
    height: 322px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: -18px;
  
  }

  .header {
    position: absolute !important;
    z-index: 100;
    left: 40px !important;
  }

  .main .picture1 {

    z-index: -1;
    width: 85% !important;
    height: 80%;
    margin: 0 0 0 15px !important;
  }

  h1 {
    font-size: 20px !important;
    max-width: 140px!important;
    width: 100% !important;
    line-height: 24px !important;

  }
  .horizontal{
    bottom: 8%;
  }
  .mySwiper-4 .swiper-pagination{
    margin-top: -5px;
    
    }
    /* Dot  */
    .swiper-pagination-bullet{
      border: 1px solid #fff !important;
    }
    .swiper-pagination-bullet-active{
      background: #ffffff !important;
    }


}


@media screen and (max-width:375px) {
  .horizontal{
    bottom: 3% !important;
    left: 67%;
  }
  .mySwiper-4 .swiper-pagination{margin-top: 7px !important;}
}

@media screen and (max-width:320px) {
  .all {
    height: 322px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: -35px;
  }
  .vertical {
    height: 32%;
    position: absolute;
    left: -6%;
    top: 3px;
}
  .horizontal{
    bottom: 9% !important ;
    left: 67%;
  }
  .mySwiper-4 .swiper-pagination{margin-top: -8px !important;}
  
}
