.div {
  background: url("../../public/images/port-background.svg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-position: center; */
  background-size: cover;
  overflow: hidden;
  margin-bottom: 80px;
}
.rootblock{
  overflow: hidden;
}
.banner{
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
 
  margin-bottom: 50px;


}
.banner-img {
  position: relative;
  max-width: 920px;
  width: 100%;
  height: 530px;
  margin-top: -130px;
  margin-bottom: 0px;
  z-index: 100;
  z-index: 11;
  pointer-events: none;
}
.banner-decor1{
  position: absolute;
  top: 0;
  left: 0;
   height: 900px;
  z-index: -1;
}
.banner-decor2{
  position: absolute;
  width: 100%;
 
  bottom: 0;
  right: 0;
 
  z-index: -1;

}
.port-boxes {
  margin-top: 50px;
  max-width: 1440px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.boxes {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 650px;
  height: 500px;
  border: 2px solid rgb(27 127 157 / 91%);
  ;
  display: block;
  background-color: white;
}
.boxes:before {

  color: white;
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    #1e4078 0%,
    #1e4078 14.12%,
    #429eb9 94.12%,
    #429eb9 98.04%,
    #429eb9 100%
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.4s linear;
}
.boxes:hover:before {
  opacity: 1;
}
.boxes .log {
  width: 529px;
  height: 260px;
  transition: all 0.4s linear;
  position: absolute;
  left: 11.58%;
  right: 11.87%;
  top: 80px;
  bottom: 11.08%;
  z-index: 3;
}
.boxes:hover .log {
  opacity: 0;
  transform: scale(1.1);
}
.boxes:hover .decor1 {
  display: none;
}
.boxes:hover .decor2 {
  display: none;
}
.boxes .box-content {
  color: #fff;
  width: 100%;
  /* transform: translate(-50%) scale(0); */
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0;
  /* transition: all 0.6s ease-in;
  transform: translateX(50%, -50%); */


}
.boxes:hover .box-content {

 transition: all 0.5s ease-in;
 opacity: 1;

  
}
.portfolio_btn {
  width: 241px;
  height: 54px;
  background-color: #1e4078;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 45px;
  cursor: pointer;
}
.port_header2 .title {
  margin-top: 40px;
}
.port_header2 h2 {
  align-items: center;
  /* width: 10px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  margin: 0;
}
.port_header2 .desc {
  padding: 40px 71px 0 71px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
}
.technologypart {
  margin-top: 60px;
  text-align: left;
  padding: 0 0 0 40px;
}
.technologypart p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 7.5px 0;
}
.div-logo {
  display: flex;
  gap: 10px;
}
.div-logo .logoimage {
  border-radius: 50%;
}
.decors {
  position: relative;
}

.decor2 {
  position: absolute;
  top: -27px;
  left: 5px;
  z-index: 2;
}

.decor1 {
  position: absolute;
  top: 180px;
  left: -7px;
  z-index: 1;
}


.decormob1{
  display: none;
}
.decormob2{
  display: none;
}
.decor2mob1{
  display: none;
}
.decor2mob2{
  display: none;
}



.linktopage{
  position: absolute;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
  opacity: 0.9;
}
.linktopage:hover{
  opacity: 1
}

@media screen and (max-width: 1312px) {
  .decor2 {
    position: absolute;
    top: -80px;
    left: 0px;
    z-index: 2;
}
  .boxes {
    width: 500px;
    height: 450px;
  }
  .boxes .log {
    width: 446px;
    height: 203px;
    left: 6.58%;
  }
  .port_header2 .title h2 {
    font-size: 18;
  }
  .port_header2 .desc {
    font-size: 15px;
  }
  .technologypart {
    margin-top: 10px;
  }
  .decor1 {
    top: 80px;
  }
}




@media screen and (max-width: 1024px) {
.decor2 {
  position: absolute;
  top: 0;
  left: 5px;
  width: 68%;
  z-index: 2;
}
}




@media screen and (max-width: 1010px) {
  .boxes {
    width: 450px;
    height: 400px;
  }
  .boxes .log {
    width: 417px;
    height: 180px;
    left: 5.58%;
  }
  .port_header2 .title h2 {
    font-size: 16;
  }
  .port_header2 .desc {
    padding: 20px 50px 0 50px;
    line-height: 30px;
  }
  .technologypart {
    margin-top: 7px;
  }
  .technologypart p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 0;
  }
  .decor1 {
    top: 80px;
  }
}
@media screen and (max-width: 910px) {
  /* .div {
    background: url("../../public/images/tabletback.svg");
    background-repeat: no-repeat;
    background-size: cover;
  } */
  .boxes {
    width: 310px;
    height: 290px;
  }
  .boxes .log {
    width: 220px;
    height: 107px;
    left: 20.58%;
  }
  .decor1 {
    top: 4px;
    left: -185px;
  }
  .port_header2 .title {
    margin-top: 5px;
  }
  .port_header2 h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .port_header2 .desc {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding: 12px 45px 0 45px;
    letter-spacing: 0.005em;
  }
  .banner{
height: 400px;
  }
  .banner-img{
  max-width: 490px;
  height: 350px;
  margin-top: -90px;
}

}


@media screen and (max-width: 700px) {
  .banner{margin-bottom: 0px;}
}




@media screen and (max-width: 630px) {
  .boxes {
    width: 285px;
    height: 293px;
  }
  .port_header2 .desc {
    padding: 12px 30px 0 30px;
  }
}
@media screen and (max-width: 579px) {

  .decormob1{
    display: block;
  }
  .decormob2{
    display: block;
  }
  .decor1desk{
    display: none;
  }
  .decor2desk{
    display: none;
  }


  .decor2mob1{
    display: block;
  }
  .decor2mob2{
    display: block;
  }
  .decor2desk1{
    display: none;
  }
  .decor2desk2{
    display: none;
  }
  .boxes {
    width: 300px;
    height: 275px;
}
  .div {
    background: url("../../public/images/mobback.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .boxes .log {
    width: 417px;
    height: 180px;
    left: 5.58%;
  }
  .port_header2 .title h2 {
    font-size: 18;
  }
  .port_header2 .desc {
    padding: 20px 50px 0 50px;
    line-height: 30px;
  }
  .technologypart {
    margin-top: 10px;
  }
  .technologypart p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      padding: 0px 0;
  }
  .decor1 {
    top: 80px;
  }
  .banner{
    height: 300px;
    margin-bottom: -40px;
      }
      .banner-img{
    
      max-width: 290px;
      height: 190px;
    }

    .banner-decor1{
      height: 315px;
    }
    .port-boxes {
      margin-top: 15px;
    }

}
@media screen and (max-width: 460px) {
  .boxes {
    width: 300px;
    height: 275px;
  }
  .boxes .log {
    width: 220px;
    height: 110px;
    left: 12.58%;
    top: 65px;
  }
  .decor1 {
    top: 95px;
    left: -20px;
}
  .port_header2 .title {
    margin-top: 20px;
  }
  .port_header2 h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .port_header2 .desc {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    padding: 3px 0px 0 26px;
    text-align: initial;

    letter-spacing: 0.005em;
  }
  .banner-img {
    max-width: 360px;
    width: 100%;
    height: 75%;
}
  .banner-img {
    /* margin-left: -30px;
    margin-top: -120px;  */
  }


}


@media screen and (max-width: 400px) {

  .banner-img {
    margin-top: -40px;
  }
  .boxes .log {
    width: 220px;
    height: 107px;
    left: 13.57%;
    top: 70px;
}
.banner-img {
  max-width: 360px;
  margin-left: -30px;
  margin-top: -80px;
  height: auto;
}

.div{
  margin-bottom: 40px;
}
.portfolio_btn{
  width: 170px;
  height: 40px;
  background-color: #1e4078;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 30px;
  transform: translateX(10px) translateY(10px);

}




}

@media screen and (max-width: 360px) {
  .banner-img {
    max-width: 335px;
    margin-left: -13px;
    margin-top: -50px;
    height: auto;
  }
  .div{
    margin-bottom: 40px;
  }
  .portfolio_btn{
    width: 170px;
    height: 40px;
    background-color: #1e4078;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 30px;
    transform: translateX(10px) translateY(10px);

  }

}