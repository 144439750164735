#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  /* background: #eee; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.mySwiper-4 .swiper-pagination{
margin-top: 20px;
position: absolute;
top: 91%

}
.mySwiper-4 .swiper-pagination span{
border: 5px solid #1E4078
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.mySwiper-4 .swiper-slide {
  background: transparent;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.mySwiper-4 .swiper-slide img{
      /* margin-bottom: 48px; */
      margin-bottom: 37px;
}

/* @media screen and (min-width: 1400px) {
  .mySwiper-4 .swiper-pagination {
    margin-top: 23px !important;
   
}
} */





@media screen and (min-width: 1440px) {

.mySwiper-4 .swiper-pagination {
  margin-top: 33px !important;
}

}

@media screen and (min-width: 1230px) {
  .mySwiper-4 .swiper-slide img{
    margin-bottom: 38px !important;
  }

  .mySwiper-4 .swiper-pagination {
    margin-top: 18px;
    top:93%;
   
}


@media screen and (min-width: 1116px) {
  .mySwiper-4 .swiper-slide img{
    margin-bottom: 30px !important;
  }


}

@media screen and (min-width: 1280px) {
 
  .mySwiper-4 .swiper-pagination {
    margin-top: 22px;
    top:92%;
   
}
}

@media screen and (min-width: 1270px) {
 
  .mySwiper-4 .swiper-pagination {
    margin-top: 18px;
    top:92%;
   
}
}

@media screen and (min-width: 1265px) {
 
  .mySwiper-4 .swiper-pagination {
    margin-top: 18px;
    top:93%;
   
}
}



}
@media screen and (min-width: 1225px) {
  .mySwiper-4 .swiper-slide img{
    margin-bottom: 34px !important;
  }
}


@media screen and (min-width: 1120px) {
  .mySwiper-4 .swiper-slide img{
    margin-bottom: 33px !important;
  }

}

@media screen and (min-width: 1024px) {
  .mySwiper-4 .swiper-slide img {
    /* margin-bottom: 48px; */
    margin-bottom: 30px;
}
.mySwiper-4 .swiper-pagination {
  top: 90%;
  margin-top: 19px;
}

}