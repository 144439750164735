.swiper {
  width: 100%;
  height: 100%;
}
.mySwiper-2 .swiper-button-prev::after{
  color: #1E4078;
  font-size: 17px;
}
.mySwiper-2 .swiper-button-prev{
  left:5%;
  top: 60%;
  width: 35px;
  height: 35px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
.mySwiper-2 .swiper-button-next::after{
  color: #1E4078;
  font-size: 17px;
}
.mySwiper-2 .swiper-button-next{
  right: 5%;
  top: 60%;
  width: 35px;
  height: 35px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
.mySwiper-2 .swiper-pagination{
  bottom: 40px;
}
.mySwiper-2 .swiper-pagination-bullets {
  font-size: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1240px) {
  .swiper-container {
    width: 1240px;
  }
  .opinion{
    height: 630px !important;
  }
  .op_header {
  
    font-size: 28px !important;
    margin-top: 40px !important;
  }
  /* .firstpath{
    margin-top:269px !important;
    margin-left: 230px !important;
  } */
  .firstpath .name{
  margin-top: 10px;
  font-size: 20px;
    
  }
  .firstpath .info{
  font-size: 20px;
  margin-top: 10px;

  }
 
}
@media screen and (max-width: 1170px) {
  .swiper-container {
    width: 1170px;
  }
  .opinion{
    height: 610px !important;
  }
  .op_header {
  
    font-size: 28px !important;
    margin-top: 40px !important;
  }

  .firstpath .name{
  margin-top: 10px;
  font-size: 20px;
    
  }
  .firstpath .info{
  font-size: 20px;
  margin-top: 10px;

  }
 
}

@media screen and (max-width: 1050px) {
  .swiper-container {
    width: 1050px;
  }
  .opinion{
    height: 600px !important;
  }
  .op_header {
  
    font-size: 24px !important;
    margin-top: 40px !important;
  }
 
  .firstpath .name{
  margin-top: 10px;
  font-size: 18px;
    
  }
  .firstpath .info{
  font-size: 20px;
  margin-top: 10px;

  }
 
}
@media screen and (max-width: 970px) {
  .swiper-container {
    width: 970px;
  }
  .opinion{
    height: 590px !important;
  }
  .op_header {
  
    font-size: 22px !important;
    margin-top: 35px !important;
  }
 
  .firstpath .name{
  margin-top: 10px;
  font-size: 18px;
    
  }
  .firstpath .info{
  font-size: 20px;
  margin-top: 10px;

  }
  
}

@media screen and (max-width: 940px) {
  .swiper-container {
    width: 940px;
  }
  .opinion{
    height: 580px !important;
  }
  .op_header {
  
    font-size: 22px !important;
    margin-top: 35px !important;
  }
 
  .firstpath .name{
  margin-top: 10px;
  font-size: 18px;
    
  }
  .firstpath .info{
  font-size: 18px !important;
  margin-top: 10px;

  }
  
}
@media screen and (max-width: 860px) {
  .swiper-container {
    width: 860px;
  }
  .firstpath img{
    width: 138px !important;
    height: 121px !important;
  }
  .opinion{
    height: 540px !important;
  }
  .op_header {
  
    font-size: 22px !important;
    margin-top: 35px !important;
  }
 
  .firstpath .name{
  margin-top: 10px;
  font-size: 17px !important;
    
  }
  .firstpath .info{
  font-size: 16px !important;
  margin-top: 10px;

  }
  
  .bar p{
    font-size: 18px !important;
  }
 
}
@media screen and (max-width: 780px) {
  .swiper-container {
    width: 780px;
  }
  .mySwiper-2 .swiper-button-prev::after{
    color: #1E4078;
    font-size: 17px;
  }
  .mySwiper-2 .swiper-button-prev{
    left:43%;
    top: 85%;
    width: 35px;
    height: 35px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .mySwiper-2 .swiper-button-next::after{
    color: #1E4078;
    font-size: 17px;
  }
  .mySwiper-2 .swiper-button-next{
    right: 43%;
    top: 85%;
    width: 35px;
    height: 35px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .firstpath img{
    width: 138px !important;
    height: 121px !important;
  }
  .opinion{
    height: 480px !important;
  }
  .op_header {
  
    font-size: 22px !important;
    margin-top: 35px !important;
  }

  .firstpath .name{
  margin-top: 10px;
  font-size: 17px !important;
    
  }
  .firstpath .info{
  font-size: 16px !important;
  margin-top: 10px;

  }
  
  .bar p{
    font-size: 14px !important;
  }
 
}

@media screen and (max-width: 710px) {
  .swiper-container {
    width: 710px;
  }
  .firstpath img{
    width: 128px !important;
    height: 111px !important;
  }
  .opinion{
    height: 470px !important;
  }
  .op_header {
  
    font-size: 22px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 5px !important;

  }
  .firstpath .name{
  margin-top: 10px;
  font-size: 17px !important;
    
  }
  .firstpath .info{
  font-size: 16px !important;
  margin-top: 10px;

  }
  .bar {
  
    margin-top: 70px !important;
    margin-left: -30px !important;

  }
  .bar p{
    font-size: 13px !important;
  }
  .op_vector{
    width: 240px;
   
  }
}

@media screen and (max-width: 620px) {
  .swiper-container {
    width: 620px;
  }
  .mySwiper-2 .swiper-button-prev{
    left:45%;
    top: 88%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .mySwiper-2 .swiper-button-next::after{
    color: #1E4078;
    font-size: 12px;
  }
  .mySwiper-2 .swiper-button-prev::after{
    color: #1E4078;
    font-size: 12px;
  }
  .mySwiper-2 .swiper-pagination{
    bottom: 20px;
  }
  .mySwiper-2 .swiper-button-next{
    right: 45%;
    top: 88%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .mySwiper-2 .swiper-pagination {
    margin-bottom: 20px;
}
  .firstpath img{
    width: 108px !important;
    height: 95px !important;
  }
  .opinion{
    height: 420px !important;
  }
  .op_header {
  
    font-size: 18px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 0px !important;
    /* margin-left: 464px !important; */
  }
  .firstpath .name{
  margin-top: 10px;
  font-size: 14px !important;
    
  }
  .firstpath .info{
  font-size: 12px !important;
  margin-top: 10px;

  }
  .bar {
    
    margin-top: 60px !important;
    margin-left: 0 !important;
  }
  .bar p{
    font-size: 13px !important;
  }

}

@media screen and (max-width: 530px) {
  .swiper-container {
    width: 530px;
  }
  .mySwiper-2 .swiper-pagination {
    margin-bottom: 20px;
}
  .firstpath img{
    width: 101px !important;
    height: 91px !important;
  }
  .opinion{
    height: 410px !important;
  }
  .op_header {
  
    font-size: 18px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 136px !important;
    
  }
  .firstpath .name{
  margin-top: 10px;
  font-size: 16px !important;
    
  }
  .firstpath .info{
  font-size: 12px !important;
  margin-top: 10px;

  }
  .bar {
  
    
    margin-top: 60px !important;
    margin-left: 0 !important;
  }
  .bar p{
    font-size: 12px !important;
  }

}


@media screen and (max-width: 530px) {
  .swiper-container {
    width: 530px;
  }
  .mySwiper-2 .swiper-pagination {
    margin-bottom: 20px;
}
.mySwiper-2 .swiper-button-prev{
  left:41%;
  top: 85%;
  width: 25px;
  height: 25px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
.mySwiper-2 .swiper-button-next::after{
  color: #1E4078;
  font-size: 13px;
}
.mySwiper-2 .swiper-button-prev::after{
  color: #1E4078;
  font-size: 13px;
}
.mySwiper-2 .swiper-button-next{
  right: 41%;
  top: 85%;
  width: 25px;
  height: 25px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
  .firstpath img{
    width: 101px !important;
    height: 91px !important;
  }
  .opinion{
    height: 360px !important;
  }
  .op_header {
    display: none;
    font-size: 18px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 20px !important;
   
  }
 
  .firstpath .name{
  margin-top: 10px;
  font-size: 16px !important;
    
  }
  .firstpath .info{
  font-size: 12px !important;
  margin-top: 10px;

  }
  .bar {
   
    
    margin-top: 60px !important;
    margin-left: 0 !important;
  }
  .bar p{
    font-size: 11px !important;
  }

}
@media screen and (max-width: 500px) {
  .swiper-container {
    width: 470px;
  }

  .mySwiper-2 .swiper-pagination {
    margin-bottom: 0px;
}
.mySwiper-2 .swiper-button-next{
  right: 42%;
  top: 85%;
  width: 24px;
  height: 24px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
.mySwiper-2 .swiper-button-prev{
  right: 42%;
  top: 85%;
  width: 24px;
  height: 24px;
  background: rgba(253, 253, 253, 0.2);
  border-radius: 4px
  
}
  .firstpath img{
    width: 91px !important;
    height: 81px !important;
  }
  .opinion{
    height: 300px !important;
  }
  .op_header {
  
    font-size: 16px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 0px !important;

  }
  .firstpath .name{
  margin-top: 10px;
  font-size: 13px !important;
    
  }
  .firstpath .info{
  font-size: 8px !important;
  margin-top: 10px;

  }
  .bar {
  
    
    margin-top: 20px !important;
    
  }
  .bar p{
    font-size: 10px !important;
  }

}

@media screen and (max-width: 470px) {
  .swiper-container {
    width: 470px;
  }
  .mySwiper-2 .swiper-pagination {
    margin-bottom: 20px;
}
  .firstpath img{
    width: 91px !important;
    height: 81px !important;
  }
  .opinion{
    height: 260px !important;
  }
  .op_header {
  
    font-size: 16px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    margin-top: 0px !important;
   
  }
  .firstpath .name{
  margin-top: 10px;
  font-size: 12px !important;
    
  }
  .mySwiper-2 .swiper-button-prev{
    left:43%;
    top: 90%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .mySwiper-2 .swiper-button-prev::after{
    font-size: 12px;
  }
  .mySwiper-2 .swiper-button-next::after{
    color: #1E4078;
    font-size: 12px;
  }
  .mySwiper-2 .swiper-button-next{
    right: 43%;
    top: 90%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .firstpath .info{
  font-size: 8px !important;
  margin-top: 10px;

  }
  .bar {
   
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
  .bar p{
    font-size: 9px !important;
  }
  .op_vector{
    width: 152px;
  

    }
    .mySwiper-2 .swiper-pagination{
      bottom: -10px;
    }
}

@media screen and (max-width: 450px) {
  .swiper-pagination-bullet{
    background: rgba(253, 253, 253, 1) !important;
  }
  .swiper-container {
    width: 410px;
  }
  .mySwiper-2 .swiper-pagination {
    margin-bottom: 20px;
}
  .firstpath img{
    width: 81px !important;
    height: 71px !important;
  }
  .opinion{
    height: 290px !important;
  }
  .op_header {
  
    font-size: 16px !important;
    margin-top: 35px !important;
  }
  .firstpath{
    
    margin-top:40px !important;
    
  }
  .firstpath .name{
  margin-top: 15px;
  font-size: 13px !important;
  line-height: 0;
  font-family: 'Arm Hmks Bebas Neue';
  font-style: normal;
  font-weight: 600;
  font-family: websoft_title;
  color: #FDFDFD;
    
  }
 
  .firstpath .info{
  font-size: 8px !important;
  line-height: 0px;
  width: 150px;
  height: 30px;

  }
  .mySwiper-2 .swiper-button-prev{
    left:43%;
    top: 90%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .mySwiper-2 .swiper-button-prev::after{
    font-size: 13px;
  }
  .mySwiper-2 .swiper-button-next::after{
    color: #1E4078;
    font-size: 13px;
  }
  .mySwiper-2 .swiper-button-next{
    right: 43%;
    top: 90%;
    width: 20px;
    height: 20px;
    background: rgba(253, 253, 253, 0.2);
    border-radius: 4px
    
  }
  .bar {
    
    margin-top: 21px !important;
    margin-left: 0px !important;
  }
  .bar p{
    font-size: 10px !important;
  }
  
 
}

