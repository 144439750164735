@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}
@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}
.teamh{
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #1E4078;
  font-family: websoft_title;
}
.ourteam {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ourteam .teamh {
  color: rgb(30, 64, 120);
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
}

.team_members {
  display: flex;
  /* gap: 65px; */

  margin-bottom: 140px;
  margin-top: 140px;
  width: 1328px;
  justify-content: center;
}

.column1 {
  display: flex;
  flex-direction: column;
  gap: 185px;
}

.column2 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  position: relative;
  top: 138px;
  gap: 185px;
}

.column3 {
  display: flex;
  flex-direction: column;
  gap: 185px;
}

.team_box {
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 440px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-bottom: 24px solid rgb(30, 64, 120);
}
.team_box h3 {
  color: rgb(30, 64, 120);
}

.team_img {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  border: 10px solid rgb(30, 64, 120);
  object-fit: cover;
  pointer-events: none;
}

.img_div {
  position: relative;
  bottom: 100px;
}

.text {
  position: relative;
  bottom: 80px;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text .description {
  width: 350px;
}

.text h3{
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
text-align: center;
color: #00003A;
font-family: websoft_title;
}
.proffesion{
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: flex-end;
text-align: center;
color: #000000;
font-family: websoft_text;
}

.team_mem_mobile{
  display: none;
}


@media screen and (max-width: 1300px) {
  .team_box {
    width: 310px;
    height: 340px;
  }
  .text .description {
    width: 250px;
  }
  .team_img {
    width: 210px;
    height: 210px;
  }
}
@media screen and (max-width: 992px) {
  /* .column3 {
    display: none;
  } */
  .column2 {
    gap: 140px;
}

  .team_members_desktop{
    display: none;
  }
  .team_mem_mobile{
    display: flex;
  }
  .column2{top:0px;}
  .ourteam .teamh {
   
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
  }
  .text .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
  }
  .team_members {
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .column2 {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .team_members{gap: 20px;}
  .proffesion{line-height: 0px;}
}
@media screen and (max-width: 660px) {
  .team_box {
    width: 157px;
    height: 252px;
  }
  .team_img {
    width: 120px;
    height: 120px;
    border: 5px solid rgb(30, 64, 120);
    /* top: 150px; */
  }
  .text .description {
    width: 130px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
  }
  .text h3 {
    font-size: 16px;
  }
  .text .proffesion {
    font-size: 11px;
    margin-top: -5px;
  }
  .text {
    margin-top: 45px;
  }
  .img_div {
    top: -30px;
  }
  .ourteam .teamh {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }
  .team_members{
    margin-top: 170px;
    margin-bottom: 30px;
  }
  .column2{top:-70px;}
  .column1 {
    gap: 140px;
}

}
@media screen and (max-width: 340px){
  .team_members{
    gap: 2px;
  }
}
