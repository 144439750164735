.mobile-version {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: -22px !important;
}
.block-mob {
  display: none;
}

.hamb {
  margin-right: 20px;
}
.hambicon {
  font-size: 25px;
  color: rgba(30, 64, 120, 1);
}

@media screen and (max-width: 579px) {
  .block-mob {
    display: block;
  }
  .div {
    background: url("../../../public/images/mobback.svg");
  }

  .mob-nav ul li a {
    text-decoration: none;
  }

  .nav-links {
    display: none;
  }
  .mob-nav {
    position: fixed;
    left: 0;
    top: 0px;
    bottom: 0;
    list-style: none;
    width: 100%;
    height: 100vh;
    background: url("../../img/window.svg");
   background-position: center;
   
  background-size: cover;
  background-repeat: no-repeat;


    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-out;

    z-index: 1000;
  }
  .nav-links-mobile{
    height: 100%;
    margin-top: 150px;
    
   

  }
  .nav-links-mobile  li{
    margin-top: 15px;
    font-size: 20px;
  }
  .nav-links-mobile  li a {
    color: white;
    text-align: center;
    font-weight: 700;
    padding: 0px 30px;
    width: 100%;
    margin-top: 15px;
    transition: all 0.5s ease;
  }
  .hamb button {
    border: none;
   position: relative;
   z-index: 1001;
   background: transparent;
  }
  .cont-info{
    max-width: 540px;
    width: 100%;
    margin-left: unset;
  }

}
