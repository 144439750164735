.swiper {
  width: 100%;
  height: 98%;
}
.spasi{
  position: relative;
}
.bs-icons{
  position: absolute;
  right: 70px;
  bottom: -3px;
  display: flex;
  gap: 40px;

}
.bs-icons .Arrow{
  color:#1E4078;
  cursor: pointer;
}

.mySwiper-3 .swiper-pagination {
  position: relative !important;
  margin-top: 50px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container {
  width: 450px;
}
@media screen and (max-width: 600px) {
  .swiper-container {
    width: 600px;
  }
}
@media screen and (max-width: 900px) {
  .swiper-container {
    width: 900px;
  }
}

@media screen and (max-width: 1370px) {
  .swiper-container {
    width: 1370px;
  }
  .portfolio_box {
    width: 430px !important;
    height: 450px !important;
  }
  .img-comp {
    width: 369px !important;
  }
}
@media screen and (max-width: 1310px) {
  .swiper-container {
    width: 1310px;
  }
  .portfolio_box {
    width: 410px !important;
    height: 430px !important;
  }
  .img-comp {
    width: 340px !important;
  }
}
@media screen and (max-width: 1230px) {
  .swiper-container {
    width: 1250px;
  }
  .portfolio_box {
    width: 390px !important;
    height: 430px !important;
  }
  .img-comp {
    width: 320px !important;
  }
}
@media screen and (max-width: 1190px) {
  .swiper-container {
    width: 1190px;
  }
  .portfolio_box {
    width: 380px !important;
    height: 410px !important;
  }
  .img-comp {
    width: 310px !important;
  }
}
@media screen and (max-width: 1160px) {
  .swiper-container {
    width: 1160px;
  }
  .portfolio_box {
    width: 340px !important;
    height: 380px !important;
  }
  .img-comp {
    width: 290px !important;
  }
  .portfolio_box h3 {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1060px) {
  .swiper-container {
    width: 1060px;
  }
  .portfolio_box {
    width: 320px !important;
    height: 360px !important;
  }
  .img-comp {
    width: 280px !important;
  }
  .portfolio_box h3 {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1001px) {
  .swiper-container {
    width: 1001px;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 400px !important;
    height: 460px !important;
  }
  .img-comp {
    width: 370px !important;
  }
  .portfolio_box h3 {
    font-size: 24px !;
  }

}


@media screen and (max-width: 845px) {

.mySwiper-3 .swiper-pagination{margin-top: 45px;}
}

@media screen and (max-width: 821px) {
  .port_header h3 {
    margin-top: 60px;
  }
  .swiper-container {
    width: 821px;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 370px !important;
    height: 400px !important;
  }
  .img-comp {
    width: 340px !important;
  }
  .portfolio_box h3 {
    font-size: 22px !;
  }
}
@media screen and (max-width: 771px) {
  .swiper-container {
    width: 771px;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 350px !important;
    height: 390px !important;
  }
  .img-comp {
    width: 320px !important;
  }
  .portfolio_box h3 {
    font-size: 22px !;
  }
}


@media screen and (max-width: 750px) {
  .mySwiper-3 .swiper-pagination{
    margin-top: 85px;
    margin-bottom: -7px;
  }
  .bs-icons {
    position: absolute;
    right: 30px;
    bottom: 14px;
    display: flex;
    gap: 22px;
}
}


@media screen and (max-width: 701px) {
  .swiper-container {
    width: 701px;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 300px !important;
    height: 340px !important;
  }
  .img-comp {
    width: 270px !important;
  }
  .portfolio_box h3 {
    font-size: 22px !;
  }
  .bs-icons{
   
    right: 0px;
    bottom: 0px;
   
  
  }
}
@media screen and (max-width: 700px) {
  .mySwiper-3 .swiper-pagination {
    margin-top: 45px;
  }
}

@media screen and (max-width: 650px) {
  .swiper-container {
    width: 701px;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 280px !important;
    height: 310px !important;
  }
  .img-comp {
    width: 260px !important;
  }
  .portfolio_box h3 {
    font-size: 22px !;
  }
  .bs-icons{
    right: 0px;
    bottom: 0px;
  }

  .mySwiper-3 .swiper-pagination{
    margin-top: 50px;
    margin-bottom: -7px;
  }



}
@media screen and (max-width: 601px) {
  .swiper-container {
    width: 100%;
  }
 
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: 20px;
  }
  .portfolio_box {
    width: 220px !important;
    height: 260px !important;
  }
  .img-comp {
    padding-top: 11px!important;
    width: 150px !important;

  }
  .img-comp img{
    margin-top: 11px!important;
    width: 150px !important;
  }
  .portfolio_box h3 {
    width: 200px!important;
    font-size: 14px!important ;
    text-align: center;
    line-height: 17px!important;
  }

  .bs-icons{
   
    right: 0px;
    bottom: 0px;
   
  
  }

      /* Dot Start */
      .portfolio-slider-cont  .swiper-pagination-bullet{
        border: 1px solid #1E4078!important;
      }
      .portfolio-slider-cont .swiper-pagination-bullet-active{
        /* background: #1E4078 !important; */
      }
      /* Dot End  */

}


@media screen and (max-width: 578px) {
.mySwiper-3 .swiper-pagination{
  margin-top: 20px;
  margin-bottom: 10px;
}
}


@media screen and (max-width: 501px) {
  .swiper-container {
    width: 100%;
  }
  .mySwiper-3 .swiper-pagination {
    position: relative !important;
    margin-top: -13px;
  }
  .portfolio_box {
    width: 158px !important;
    height: 185px !important;
  }
  .img-comp {
    padding-top: 11px!important;
    width: 100px !important;

  }
  .img-comp img{
    margin-top: 11px!important;
    width: 100px !important;
  }
  .portfolio_box h3 {
    width: 140px!important;
    font-size: 12px!important ;
    text-align: center;
    line-height: 15px!important;
  }

  .bs-icons{
   
    right: 20px;
    bottom: 0px;
   
  
  }
  .bs-icons .Arrow{
    width: 8px;
  }

  .bs-icons {
    gap: 20px;
}
    /* Dot Start */
    .portfolio-slider-cont  .swiper-pagination-bullet{
      border: 1px solid #1E4078!important;
    }
    .portfolio-slider-cont .swiper-pagination-bullet-active{
      /* background: #1E4078 !important; */
    }
    /* Dot End  */

}

@media screen and (max-width: 450px) {
  .mySwiper-3 .swiper-pagination{
      margin-top: 20px;
      margin-bottom: 10px;
  }
}


@media screen and (max-width: 350px) {
 

  .portfolio_box {
    width: 140px !important;
    height: 175px !important;
  }

    /* Dot Start */
    .portfolio-slider-cont  .swiper-pagination-bullet{
      border: 1px solid #1E4078!important;
    }
    .portfolio-slider-cont .swiper-pagination-bullet-active{
      /* background: #1E4078 !important; */
    }
    /* Dot End  */


}