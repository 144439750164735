.opinion {
  background-color: #1e4078;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 640px;
  width: 100%;
  position: relative;
  margin-top: 80px;
  /* padding-bottom: 60px; */
}

.op_header {
  color: #fff;
  font-size: 30px;
  margin-top: 40px;
}
.op_header h2{
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #1E4078;
  font-family: websoft_title;
}

.op_three {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  height: 400px;
  padding-right: 30px;
  padding-left: 30px;
}

.bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 10px 0px;
  /* width: 560px; */
  padding: 5px;
  margin-bottom: 30px;
  margin-top: 50px;
  margin-left: 40px;
  background-color: #fff;
}

.bar p {
  font-size: 20px;
}

.bar2 {

  position: absolute;
  top: 25%;
  left: 40%;
  max-width: 500px;
  width: 100%;
  padding: 25px 20px;
  
  z-index: 2;
}

.left {
  position: absolute;
  align-self: flex-start;
}

.left:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 40px;
  bottom: 0px;
  left: -19px;
  -webkit-clip-path: polygon(100% 53%, 0% 100%, 100% 100%);
  clip-path: polygon(100% 53%, 0% 100%, 100% 100%);
  background-color: #fff;
}

.firstpath img {
  height: 141.99411010742188px;
  width: 158.86317443847656px;


}

.firstpath {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  margin-top: 300px;
}

.firstpath p {
  color: white;
  margin-top: 15px;

}

.firstpath .name {
  font-family: Arm Hmks Bebas Neue;
  font-size: 20px;
  font-weight: 400;
  
  letter-spacing: 0em;
  text-align: center;

}

.firstpath .info {
  font-family: Arm Hmks Bebas Neue;
  font-size: 22px;
  font-weight: 400;
 
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.op_vector {
  position: relative;
  top:50px;
  max-width: 450px;
  width: 100%;
  z-index: 1;
}

.firstpath img {
  width: 158px;
  height: 141px;
}
@media(max-width: 1720px){
  .bar2 {
    left: 35%;
  }
}
@media(max-width: 992px){
  .bar2 {
    left: 30%;
    max-width: 400px;
  }
}
@media(max-width: 780px){
  .bar2 {
   max-width: 400px;
   top: 0;
   padding: 15px;
  }
  .firstpath{
    margin-top: 0px;
  }
  .op_vector {
    left: 10%;
    top:-15%;
    max-width: 300px;
  }
  .op_three {
    padding-right: 15px;
    padding-left: 15px;
  }
  .op_header h3{
    display: none;
  }
  
}
@media(max-width: 710px){
  .bar2 {
    left: 40%;
    max-width: 250px;
  }
}
@media(max-width: 576px){
  .bar2 {
  
    max-width: 200px;
  }
  .left:after {
  
    width: 15px;
    height: 30px;
    bottom: 0px;
    left: -10px;

  }
  .opinion{
    margin-top: 20px;
  }
  .bar2 {
    max-width: 200px;
    height: 90px;
}
.bar2 {
  padding: 7px;
}
}
@media(max-width: 410px){
  .bar2 {
    max-width: 230px;
    height: 75px;
  }
  .bar2 {
    left: 37%;
    top: 30px;
}

  
}