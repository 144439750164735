*{
  /* border: 1px solid red; */
  margin: 0  ;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  /* max-width: 1440px; */
  
}
.root-cont{
  overflow: hidden;
}
a{
  text-decoration: none;
  cursor: pointer;
}

body {
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}