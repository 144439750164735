@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}

.question-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}
.question-part .ques-text h2 {
  color: #1e4078;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 40px;
  font-family: websoft_title;
}

.main_div {
  /* width: 1100px; */
  gap: 40px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5%;
}

.main-heading {
  height: 75px;
  width: 1104px;
  height: 96px;
  border: 1px solid transparent;
  box-shadow: 0px 10px 3px rgb(243, 240, 240);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
  
  
}

.main-heading h3 {
  font-size: 20px;
  font-weight: 400;
  margin-left: 36px;
}
.main-heading p {
  font-size: 25px;
  margin-right: 48px;
  color: #1e4078;
  cursor: pointer;
  
}

.main-heading-text {
  border: 2px solid white;
  display: flex;
  align-items: center;
  width: 1030px;
  margin-left: 36px;
  font-size: 20px;
  line-height: 27px;
}
.main-heading:hover {
  border: 1px solid rgb(23, 22, 22);
  box-shadow: 0px 10px 3px rgb(243, 240, 240);
  background: #1f4078;
  color: white;
  transition: 0.5s;
}
.main-heading:hover svg path {
   fill: white;
}

@media screen and (max-width: 1200px) {
  .main-heading {
    width: 800px;
  }
}

@media screen and (max-width: 1024px) {
.main-heading-text {
  border: 2px solid white;
  display: flex;
  align-items: center;
  width: 800px;
  margin-left: 6px;
  font-size: 20px;
  line-height: 27px;
}
}

@media screen and (max-width: 870px) {
  .question-part .ques-text h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  .main-heading {
    width: 700px;
  }
  .main-heading-text {
    width: 600px;
  }
}
@media screen and (max-width: 770px) {
  .main-heading {
    width: 480px;
  }
  .main-heading h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .main-heading-text {
    width: 400px;
    font-size: 16px;
  }
  .question-part{
    margin-top: 60px;
  }
}
@media screen and (max-width: 570px) {
  .main_div{
    gap: 0;
  }
  .main-heading {
    width: 335px;
    height: 44px;
    gap: 0;
    margin-bottom: 10px;
  }
  .main-heading h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    padding: 5px;
   
  }
  .question-part .ques-text h2 {
    margin-bottom: 15px;
}
.main-heading p {
  font-size: 20px;
}
  .main-heading p {
    margin-right: 25px;
    margin: 0;
    padding: 0px 5px 0 0;
  }
  .main-heading-text {
    width: 333px;
    font-size: 13px;
    margin-left: 0px;
    margin-bottom: 10px;
    line-height: 21px;
  }
  .question-part .ques-text h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }
  .question-part{
    margin-top: 20px;
  }



}




@media screen and (max-width: 320px) {
  .main_div{
    gap: 0;
  }
  .main-heading {
    width: 290px;
    height: 50px;
    gap: 0;
    margin-bottom: 10px;
  }
  .main-heading-text {
    width: 280px;
    font-size: 13px;
    margin-left: 7px;
    margin-bottom: 10px;
    line-height: 20px;
}





}
