@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}
@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}

.about {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 101px;
  padding-left: 30px;
  padding-right: 30px;
}
.about .abt_pictxt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}
.about .abt_pictxt img {
  width: 340px;
  height: 340px;
  border-radius: 50%;
  border: 10px solid #EAFEFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.abt_pictxt a{
  color: #1d4078;
  font-size: 27px;
}
.Ceo{
  width: 370px;
  height: 370px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 20px solid #1e4078; */
  background: #1e4078;
  margin-top: 50px;
}
.about .abt_pictxt h3 {
  color: #1e4078;
  font-size: 40px;
  margin-top: 20px;
  font-style: normal;
  font-family: websoft_title;
}
.about .abt_pictxt p {
  color: #1e4078;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: websoft_text;
}


.about .abt_txts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
}
.abt-title {
  text-align: center;
  color: #1e4078;
  font-weight: 400;
  font-size: 33px;
  line-height: 48px;
  display: none;
  font-family: websoft_title;
  
}
.about .abt_txts h3 {
  text-align: center;
  color: #1e4078;
  font-weight: 400;
  font-size: 48px;
  line-height: 57px;
  font-family: websoft_title;
}
.abt-txt-p {
  /* padding: 0 22px; */
  margin-top: 40px;
  margin-bottom: 40px;
  /* width: 761px; */
  /* height: 175px; */
  color:#100F0F;
  font-weight: 400;
  font-size: 23px;
  line-height: 37px;
  font-family: websoft_text;
}
.abt-sup-ket {
  width: 14px;
  height: 14px;
  margin-left: 20px;
}

.abt-sup-p {
  -webkit-text-decoration: underline 2px #1e4078;
  text-decoration: underline 2px #1e4078;
  text-underline-offset: 10px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #100F0F;
  font-family: websoft_text;
}
.abt-sup-cont {
  display: flex;
  align-items: center;
  height: 27px;
  margin-bottom: 32px;
}
.abt_sup ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.abt_sup li {
  display: block;
  align-items: center;
  padding: 1px 0;
  list-style: none;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
  font-family: websoft_text;
}
.our-company{
  display: block;
  align-items: center;
  padding: 1px 0;
  list-style: none;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 20px;
  font-family: websoft_text;  
}


@media screen and (max-width: 1224px) {
  .about .abt_pictxt img {
    width: 310px;
    height: 310px;
   
    border: 17px solid #EAFEFF;
  }
  .about .abt_txts h3 {
    font-size: 37px;
  }

  .abt-txt-p {
    font-size: 22px;
    font-weight: 600;
  }
  .about{
    margin-top: 85px;
  }
  .Ceo {
    width: 340px;
    height: 340px}
}


@media screen and (max-width: 1024px) {
  .about .abt_pictxt img {
    width: 310px;
    height: 310px;
  
    border: 18px solid #EAFEFF;
  }
  .about .abt_txts h3 {
    font-size: 35px;
  }
  .abt-txt-p {
    font-size: 18px;
    font-weight: 600;
  }
  .Ceo {
    width: 340px;
    height: 340px;
  }

  .abt-txt-p {
    /* padding: 0 22px; */
    margin-top: 20px;
    margin-bottom: 25px;
    /* width: 761px; */
    /* height: 175px; */
    color: #100F0F;
    font-weight: 400;
    font-size: 23px;
    line-height: 35px;
    font-family: websoft_text;
}
.abt_sup li {
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 14px;
}

}



/* @media screen and (max-width: 824px) {
  .about .abt_pictxt img {
    width: 280px;
    height: 280px;
    
    border: 16px solid #EAFEFF;
  }
  .Ceo{
    width: 300px;
    height: 300px;}
  .about .abt_txts h3 {
    font-size: 28px;
  }
  .about .abt_txts{
    padding: 0;
  }
  .abt-txt-p {
    font-size: 14px;
    font-weight: 600;
  }
  .abt_sup li {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .about {
    margin-top: 75px;
  }
  .abt-sup-p{
    font-size: 20px;
  }
} */


/* @media screen and (max-width: 768px){
 
  .about{
    flex-direction: column;
  }
  .Ceo{
   width: 310px;
   height: 310px;}
}
 */




 @media screen and (max-width: 980px) {
  .about .abt_txts {
    padding: 0 0;
}
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 50px;
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom: 8px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 35px !important;
    line-height: 75px;

}
.about .abt_pictxt h3 {
  font-size: 24px;
  margin-top: 11px;
}
.about .abt_pictxt p{
  font-size: 14px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 20px;
  line-height: 29px;
  margin: 15px 15px;
}

.abt-sup-p {
  width: 97%;
  font-size: 18px !important;
  /* margin-right: 485px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 10px;
  height: 10px;
}
.abt_sup li {
  font-size: 18px !important;
  font-weight: 700;
  margin-left: 6px !important;
}
.abt-sup-cont{
  height: 21px !important; 
}
.Ceo{
  width: 235px;
  height: 235px;
  margin-top: 0px;
}
.our-company {
  font-size: 20px;
  line-height: 29px;
  margin-left: 12px;
  margin-bottom: 11px;
}
.team_members {
  margin-top: 100px;
}

.about .abt_pictxt img {
  width: 220px;
  height: 220px;
  border: 7px solid #EAFEFF;
}


}















 @media screen and (max-width: 915px) {
  .about .abt_txts {
    padding: 0 0;
}
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 50px;
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom: 8px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 35px !important;
    line-height: 75px;

}
.about .abt_pictxt h3 {
  font-size: 24px;
  margin-top: 11px;
}
.about .abt_pictxt p{
  font-size: 14px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 18px;
  line-height: 26px;
  margin: 15px 6px;
}

.abt-sup-p {
  width: 97%;
  font-size: 18px !important;
  /* margin-right: 485px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 10px;
  height: 10px;
}
.abt_sup li {
  font-size: 17px !important;
  font-weight: 700;
  margin-left: 5px !important;
}
.abt-sup-cont{
  height: 17px !important; 
}
.Ceo{
  width: 235px;
  height: 235px;
  margin-top: 0px;
}
.our-company {
  font-size: 18px;
  line-height: 25px;
  margin-left: 8px;
  margin-bottom: 11px;
}
.team_members {
  margin-top: 100px;
}

.about .abt_pictxt img {
  width: 220px;
  height: 220px;
  border: 7px solid #EAFEFF;
}


}










 @media screen and (max-width: 880px) {
  .about .abt_txts {
    padding: 0 0;
}
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 50px;
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom: 8px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 35px !important;
    line-height: 75px;

}
.about .abt_pictxt h3 {
  font-size: 24px;
  margin-top: 11px;
}
.about .abt_pictxt p{
  font-size: 14px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 18px;
  line-height: 26px;
  margin: 15px 6px;
}

.abt-sup-p {
  width: 97%;
  font-size: 18px !important;
  /* margin-right: 485px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 10px;
  height: 10px;
}
.abt_sup li {
  font-size: 17px !important;
  font-weight: 700;
  margin-left: 5px !important;
}
.abt-sup-cont{
  height: 17px !important; 
}
.Ceo{
  width: 235px;
  height: 235px;
  margin-top: 0px;
}
.our-company {
  font-size: 18px;
  line-height: 25px;
  margin-left: 8px;
  margin-bottom: 11px;
}
.team_members {
  margin-top: 100px;
}

.about .abt_pictxt img {
  width: 220px;
  height: 220px;
  border: 7px solid #EAFEFF;
}


}









 @media screen and (max-width: 810px) {
  .about .abt_txts {
    padding: 0 0;
}

  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 50px;
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom: 8px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 35px !important;
    line-height: 75px;

}
.about .abt_pictxt h3 {
  font-size: 24px;
  margin-top: 11px;
}
.about .abt_pictxt p{
  font-size: 14px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 18px;
  line-height: 26px;
  margin: 15px 6px;
}

.abt-sup-p {
  width: 97%;
  font-size: 18px !important;
  /* margin-right: 535px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 9px;
  height: 9px;
}
.abt_sup li {
  font-size: 17px !important;
  font-weight: 700;
  margin-left: 5px !important;
}
.abt-sup-cont{
  height: 17px !important; 
}
.Ceo{
  width: 235px;
  height: 235px;
  margin-top: 0px;
}
.our-company {
  font-size: 18px;
  line-height: 25px;
  margin-left: 8px;
  margin-bottom: 11px;
}
.team_members {
  margin-top: 100px;
}

.about .abt_pictxt img {
  width: 220px;
  height: 220px;
  border: 7px solid #EAFEFF;
}


}










@media screen and (max-width: 768px) {
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 50px;
  }
  .about .abt_txts {
    padding: 0 0;
}
  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom:12px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 35px !important;
    line-height: 75px;

}
.about .abt_pictxt h3 {
  font-size: 24px;
  margin-top: 11px;
}
.about .abt_pictxt p{
  font-size: 14px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 18px;
  line-height: 26px;
  margin: 15px 6px;
}

.abt-sup-p {
  width: 97%;
  font-size: 18px !important;
  /* margin-right: 495px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 9px;
  height: 9px;
}
.abt_sup li {
  font-size: 17px !important;
  font-weight: 700;
  margin-left: 5px !important;
}
.abt-sup-cont{
  height: 17px !important; 
}
.Ceo{
  width: 235px;
  height: 235px;
  margin-top: 0px;
}
.our-company {
  font-size: 18px;
  line-height: 25px;
  margin-left: 8px;
  margin-bottom: 11px;
}
.team_members {
  margin-top: 100px;
}

.about .abt_pictxt img {
  width: 220px;
  height: 220px;
  border: 7px solid #EAFEFF;
}


}





@media screen and (max-width: 700px){
  .abt-sup-p{
    font-size: 16px;
  }
 
}
@media screen and (max-width: 640px) {
  .about .abt_pictxt img {
    width: 100px;
    height: 100px;

    border: 3px solid #EAFEFF;
  }
  .Ceo{
    width: 110px;
    height: 110px;}
  .about .abt_txts h3 {
    font-size: 25px;
  }

  .abt-txt-p {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  }
  .abt_sup li {
    font-size: 15px !important;
  }
  .about {
    margin-top: 21px;
  }
}

@media screen and (max-width: 576px) {
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: 25px;
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sup-cont{
    margin-bottom: 8px;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }

  .abt-title {
    text-align: center;
    color: #1e4078;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 50px;
    /* display: none; */

}
.about .abt_pictxt h3 {
  font-size: 16px;
  margin-top: 10px;
}
.about .abt_pictxt p{
  font-size: 12px;
  margin-top: 5px;
}
.abt-txt-p {
  font-size: 14px;
  line-height: 21px;
  margin: 8px 8px;
}

.abt-sup-p {
  width: 97%;
  font-size: 14px !important;
  /* margin-right: 170px !important; */
  margin-bottom: 15px !important;
}

.abt-sup-ket {
  width: 8px;
  height: 8px;
}
.abt_sup li {
  font-size: 14px !important;
  font-weight: 400;
  margin-left: 5px !important;
}
.abt-sup-cont{
  height: 17px !important; 
}
.Ceo{margin-top: 0px;}
.our-company {
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
  margin-bottom: 10px;
}
.team_members {
  margin-top: 100px;
}

}


@media screen and (max-width: 425px) {
  .abt-txt-p {
    font-size: 14px;
    line-height: 20px;
    margin: 6px 0px;
}
.abt-sup-p {
  font-size: 14px !important;
  margin-right: 5px !important;
  margin-bottom: 10px !important;
}

.our-company {
  font-size: 14px;
  line-height: 20px;
  margin-left: 1px;
  margin-bottom: 6px;
}

.abt_sup li {
  font-size: 12px !important;
  font-weight: 600;
  margin-left: 5px !important;
}

.about {margin-top: -15px;}


}






@media screen and (max-width: 414px) {
  .abt-txt-p {
    font-size: 14px;
    line-height: 20px;
    margin: 6px 3px;
}
.abt-sup-p {
  width: 97%;
  text-align: start;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}

.our-company {
  font-size: 14px;
  line-height: 20px;
  margin-left: 1px;
  margin-bottom: 6px;
}

.abt_sup li {
  font-size: 12px !important;
  font-weight: 600;
  margin-left: 5px !important;
}

.about {margin-top: -15px;}


}


@media screen and (max-width: 406px) {
  .about {
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    margin-top: -25px;
    
  }

  .abt-title {
    display: block;
  }
  .abt_sup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .about .abt_txts h3 {
    display: none;
  }
  .about .abt_pictxt {
    margin: 0;
  }
  .abt-txt-p {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin: 5px 9px;
  }
}




@media screen and (max-width: 400px) {

  .abt-sup-p {
    width: 97%;
    text-align: start;
    font-size: 14px !important;
    margin-bottom: 7px !important;
    text-underline-offset: 8px;
  }
  .abt-txt-p {
    font-size: 13px !important;
    font-weight: 600;
    line-height: 20px;
    margin: 4px 1px !important;
}

}


@media screen and (max-width: 393px){
  .abt-sup-p {
    width: 97%;
    text-align: start;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .our-company {
    font-size: 13px;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
  .abt_sup li {
    font-size: 13px !important;
    font-weight: 600;
    margin-left: 5px !important;
  }
  
  
  }


@media screen and (max-width: 390px){
  .abt-sup-p {
    width: 97%;
    text-align: start;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .our-company {
    font-size: 13px;
    line-height: 21px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
  .abt_sup li {
    font-size: 13px !important;
    font-weight: 600;
    margin-left: 5px !important;
  }
  
  
  }




@media screen and (max-width: 375px){
.abt-sup-p {
  width: 92%;
  text-align: start;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}
.abt-txt-p {
  font-size: 13px !important;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 8px !important;
}
.our-company {
  font-size: 13px;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.abt_sup li {
  font-size: 13px !important;
  font-weight: 600;
  margin-left: 5px !important;
}


}

@media screen and (max-width: 360px){
  .abt-txt-p {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin: 5px 1px;
}
.our-company {
  font-size: 13px;
  line-height: 21px;
  margin-left: 8px;
  margin-bottom: 6px;
}
  .abt_sup li {
    font-size: 11px !important;
    font-weight: 600;
    margin-left: 5px !important;
  }
  
  
  }

  @media screen and (max-width: 320px){
    .abt-txt-p {
      font-size: 14px !important;
      margin-bottom: 10px !important;
  }
  .abt-sup-p {
    width: 95%;
    text-align: start;
    font-size: 12px !important;
    margin-bottom: 10px !important;
}
  .our-company {
    font-size: 13px;
    line-height: 18px;
    margin-left: 2px;
    margin-bottom: 8px;
  }
    .abt_sup li {
      font-size: 12px !important;
      font-weight: 600;
      margin-left: 5px !important;
    }
    
    
    }
