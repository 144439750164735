.workstyle {
 max-width: 1170px;
  height: 420px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  gap:80px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-left: 131px; */
  margin-top: 100px;


}
.workstyle-blok {
  width: 328px;
  height: 328px;
  justify-content: center; 
  align-items: center;
  position: relative; 

} 
.work-left-line {
  width: 220px;
  height: 190px;
  border-bottom: 10px solid #1e4078;
  border-right: 10px solid #1e4078;
  position: absolute;
  right: -10px;
  bottom: 60px;
  z-index: -1;
}
.work-top-line {
  transform: translate(-40px, -40px);
  width: 220px;
  height: 190px;
  border-top: 10px solid #1e4078;
  border-left: 10px solid #1e4078;
  position: absolute;
  z-index: -1;
}
.workstyle-info {
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  font-style: normal;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #1e4078;
  margin-top: 64px;
  font-family: websoft_text;
}
.workstyle-info-1{
  width: 200px;
}
.icon-blok {
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  flex-direction: column;
}

.workstyle-blok:hover .work-top-line{
  width: 320px;
  height: 260px;
  transition-duration: 0.5s;
}

.workstyle-blok:hover .work-left-line {
  width: 320px;
  height: 260px;
  transition-duration: 0.5s;
} 






@media screen and (max-width:1440px) {
  .work-left-line {
    width: 190px;
    height: 190px;
    right: 0px;
}
  .work-top-line {
    transform: translate(5px, -39px);
    width: 190px;
    height: 190px;
    border-top: 10px solid #1e4078;
    border-left: 10px solid #1e4078;
    position: absolute;
    z-index: -1;
}

.workstyle-blok:hover .work-top-line{
  width: 230px;
  height: 230px;
  transition-duration: 0.5s;
}

.workstyle-blok:hover .work-left-line {
  width: 230px;
  height: 230px;
  transition-duration: 0.5s;
} 


}









@media screen and (max-width:1344px) {

  .workstyle-blok{
    width: 310px;
    height: 310px;
  }
 
  
  .work-left-line{
    transform: translate(0px, 0px);
    width: 210px;
    height: 180px;
  }
  .work-top-line{
    transform: translate(-11px, -62px);
    width: 210px;
    height: 180px;
  }
  .workstyle{
    margin-left: 60px;
    justify-content: space-around;
  }
}
.workstyle-blok:hover .work-top-line{
  width: 270px;
  height: 240px;
  transition-duration: 0.5s;
}

.workstyle-blok:hover .work-left-line {
  width: 270px;
  height: 240px;
  transition-duration: 0.5s;
} 

@media screen and (max-width:1274px) {

  .workstyle-blok{
    width: 300px;
    height: 300px;
  }
  .work-left-line{
    transform: translate(0px, 0px);
    width: 200px;
    height: 170px;
  }
  .work-top-line{
    transform: translate(-11px, -62px);
    width: 200px;
    height: 170px;
  }
  .workstyle-blok:hover .work-top-line{
    width: 260px;
    height: 240px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 260px;
    height: 240px;
    transition-duration: 0.5s;
  } 
  
 
}

@media screen and (max-width:1234px) {

  .workstyle-blok{
    width: 280px;
    height: 280px;
  }
  .work-left-line{
    transform: translate(14px, 21px);
    width: 180px;
    height: 150px;
  }
  .work-top-line{
    transform: translate(-2px, -56px);
    width: 180px;
    height: 150px;
  }
  .workstyle-blok:hover .work-top-line{
    width: 240px;
    height: 220px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 240px;
    height: 220px;
    transition-duration: 0.5s;
  } 

}

@media screen and (max-width:1214px) {

  .workstyle-blok{
    width: 270px;
    height: 270px;
  }
  .work-left-line{
    transform: translate(-2px, 21px);
    width: 180px;
    height: 150px;
  }
  .work-top-line{
    transform: translate(-32px, -56px);
    width: 180px;
    height: 150px;
  }
 
  .icon-blok{
    width: 250px;
  }
}
@media screen and (max-width:1190px) {

  .workstyle-blok{
    width: 260px;
    height: 260px;
    margin-left: -29px;
  }
  .work-left-line{
    transform: translate(-18px, 24px);
    width: 170px;
    height: 140px;
  }
  .work-top-line{
    transform: translate(-28px, -48px);
    width: 170px;
    height: 140px;
  }
  .workstyle-blok:hover .work-top-line{
    width: 210px;
    height: 200px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 210px;
    height: 200px;
    transition-duration: 0.5s;
  } 
  
  .workstyle{
    justify-content: space-around;
    
  }
  .icon-blok{
    width: 220px;
  }
  .workstyle-info{
    font-size: 30px;
  }
}

@media screen and (max-width:1150px) {

  .workstyle-blok{
    width: 250px;
    height: 250px;
    margin-left: 20px;
  }
  .work-left-line{
    transform: translate(-18px, 24px);
    width: 160px;
    height: 150px;
  }
  .work-top-line{
    transform: translate(-28px, -48px);
    width: 160px;
    height: 150px;
  }

  .workstyle-blok:hover .work-top-line{
    width: 200px;
    height: 190px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 200px;
    height: 190px;
    transition-duration: 0.5s;
  } 
  .workstyle{
    justify-content: space-around;
    margin-left: 0;
    
  }
  .icon-blok{
    width: 200px;
  }
  .workstyle-info{
    font-size: 28px;
    margin-top: 58px;
    line-height: 37px;

  }
  .workstyle-icon{
    width: 38px;
    height: 38px;
  }
}






@media screen and (max-width:1080px) {

  .workstyle-blok{
    width: 220px;
    height: 220px;
    margin-left: 20px;
  }
  .work-left-line{
    transform: translate(-10px, 51px);
    width: 160px;
    height: 150px;
  }
  .work-top-line{
    transform: translate(-37px, -48px); 
    width: 160px;
    height: 150px;
  }
  .workstyle-blok:hover .work-top-line{
    width: 200px;
    height: 190px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 200px;
    height: 190px;
    transition-duration: 0.5s;
  } 
  .workstyle{
    justify-content: space-around;
    /* margin-left: -40px; */
      
  }
  .icon-blok{
    width: 200px;
  }
  .workstyle-info{
    font-size: 26px;
    margin-top: 40px;
  line-height: 35px;

  }
  .workstyle-icon{
    width: 38px;
    height: 38px;
  }
}


@media screen and (max-width:1024px) {
  .workstyle {
    margin-left: 25px;

  }
  .work-left-line {
    transform: translate(5px, 45px);
    width: 160px;
    height: 150px;
}
.workstyle {
  margin-top: 20px;
}

}



@media screen and (max-width:980px) {

  .workstyle-blok{
    width: 200px;
    height: 200px;
    margin-left: 20px;
  }
  .work-left-line{
    transform: translate(-10px, 51px);
    width: 150px;
    height: 140px;
  }
  .work-top-line{
    transform: translate(-37px, -48px); 
    width: 150px;
    height: 140px;
  }
  .workstyle{
    justify-content: space-around;
    margin-left: 23px;
      
  }
  .icon-blok{
    width: 150px;
  }
  .workstyle-info{
    font-size: 23px;
    margin-top: 42px;
    line-height: 33px;

  }
  .workstyle-info-1{
    width: 150px;
  }
  .workstyle-icon{
    width: 38px;
    height: 38px;
  }
}
@media screen and (max-width:880px) {

  .workstyle-blok{
    width: 180px;
    height: 180px;
    margin-left: 5px;
  }
  .workstyle-info-1{
    width: 140px;
  }
  .work-left-line{
    transform: translate(-10px, 51px);
    width: 110px;
    height: 110px;
    border-bottom: 8px solid #1e4078;
    border-right: 8px solid #1e4078;
  }
  .work-top-line{
    transform: translate(-37px, -48px); 
    width: 110px;
    height: 110px;
    border-top: 8px solid #1e4078;
    border-left: 8px solid #1e4078;
  }
  .workstyle{
    justify-content: space-evenly;
    margin-left: 30px;
      
  }
  .workstyle-blok:hover .work-top-line{
    width: 140px;
    height: 140px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 140px;
    height: 140px;
    transition-duration: 0.5s;
  } 
  .icon-blok{
    width: 140px;
  }
  .workstyle-info{
    font-size: 19px;
    margin-top: 40px;
    line-height: 30px;
  }
  .workstyle-info-1{
    width: 140px;
  }
  .workstyle-icon{
    width: 38px;
    height: 38px;
  }
}





@media screen and (max-width:768px) {

  .workstyle {
    margin-top: -35px;
}

  .workstyle-blok{
    width: 140px;
    height: 160px;
    margin-left: 15px;
  }
  .workstyle-info-1{
    width: 120px;
  }
  .work-left-line{
    transform: translate(1px, 40px);
    width: 85px;
    height: 85px;
    border-bottom: 7px solid #1e4078;
    border-right: 7px solid #1e4078;
  }
  .work-top-line{
    transform: translate(-19px, -28px);
    width: 85px;
    height: 85px;
    border-top: 7px solid #1e4078;
    border-left: 7px solid #1e4078;
  }
  .workstyle-blok:hover .work-top-line{
    width: 110px;
    height: 110px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 110px;
    height: 110px;
    transition-duration: 0.5s;
  }  
    .workstyle{
      justify-content: space-evenly;
      margin-left: 0px;
        
    }
      
  
  .icon-blok{
    width: 120px;
  }
  .workstyle-info{
    font-size: 17px;
    margin-top: 19px;
    line-height: 25px;
  }
  .workstyle-info-1{
    width: 130px;
  }
  .workstyle-icon{
    width: 35px;
    height: 35px;
  }
}








@media screen and (max-width:740px) {

  .workstyle-blok{
    width: 140px;
    height: 160px;
    margin-left: 15px;
  }
  .workstyle-info-1{
    width: 120px;
  }
  .work-left-line{
    transform: translate(-4px, 42px);
    width: 120px;
    height: 110px;
    border-bottom: 7px solid #1e4078;
    border-right: 7px solid #1e4078;
  }
  .work-top-line{
    transform: translate(-22px, -29px);
    width: 120px;
    height: 110px;
    border-top: 7px solid #1e4078;
    border-left: 7px solid #1e4078;
  }
  .workstyle-blok:hover .work-top-line{
    width: 140px;
    height: 140px;
    transition-duration: 0.5s;
  }
  
  .workstyle-blok:hover .work-left-line {
    width: 140px;
    height: 140px;
    transition-duration: 0.5s;
  }  
    .workstyle{
      justify-content: space-evenly;
      margin-left: 0px;
        
    }
      
  
  .icon-blok{
    width: 120px;
  }
  .workstyle-info{
    font-size: 17px;
    margin-top: 28px;
    line-height: 25px;
  }
  .workstyle-info-1{
    width: 130px;
  }
  .workstyle-icon{
    width: 35px;
    height: 35px;
  }
}



@media screen and (max-width:640px) {

  .workstyle-blok{
    width: 140px;
    height: 160px;
    margin-left: 10px;
  }
  .workstyle-info-1{
    width: 120px;
  }
  .work-left-line{
    transform: translate(-4px, 42px);
    width: 120px;
    height: 110px;
    border-bottom: 5px solid #1e4078;
    border-right: 5px solid #1e4078;
  }
  .work-top-line{
    transform: translate(-22px, -29px);
    width: 120px;
    height: 110px;
    border-top: 5px solid #1e4078;
    border-left: 5px solid #1e4078;
  }
 
    .workstyle{
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 0px;
      margin: 100px;
        
    }
      
  
  .icon-blok{
    width: 120px;
  }
  .workstyle-info{
    font-size: 18px;
    margin-top: 23px;
    line-height: 23px;
  }
  .workstyle-info-1{
    width: 130px;
  }
  .workstyle-icon{
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 580px){
.workstyle {
  margin: 40px;}
}


@media screen and (max-width: 500px){
  .workstyle {
    margin: 30px 20px;}
  }
  @media screen and (max-width: 450px){
    .workstyle {
      margin: 10px;}
    }
    


    @media screen and (max-width:425px) {
      .workstyle{margin: 24px 13px;} 
      .workstyle-blok {
        margin-left: -1px;
    }
    .work-left-line {
      transform: translate(9px, 43px);
      width: 90px;
      height: 90px;
      border-bottom: 7px solid #1e4078;
      border-right: 7px solid #1e4078;
  }
  .work-top-line {
    transform: translate(-24px, -30px);
    width: 90px;
    height: 90px;
    border-top: 7px solid #1e4078;
    border-left: 7px solid #1e4078;
  }
      
    }


    @media screen and (max-width:420px) {

      .workstyle-blok{
        /* width: 120px;
        height: 140px; */
        margin-left: 3px;
      }
      .workstyle-info-1{
        width: 100px;
      }
      .work-left-line{
        transform: translate(-35px, 35px);
        width: 60px;
        height: 60px;
        border-bottom: 5px solid #1e4078;
        border-right: 5px solid #1e4078;
      }
      .work-top-line{
        transform: translate(-30px, -15px);
        width: 60px;
        height: 60px;
        border-top: 5px solid #1e4078;
        border-left: 5px solid #1e4078;
      }
      .workstyle-blok:hover .work-top-line{
        width: 60px;
        height: 60px;
        transition-duration: 0.5s;
      }
      
      .workstyle-blok:hover .work-left-line {
        width: 60px;
        height: 60px;
        transition-duration: 0.5s;
      } 
        .workstyle{
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 37px;
          
            
        }
          
      
      .icon-blok{
      
        margin-left: -20px;
    margin-top: 10px;
      }
      .workstyle-info{
        font-size: 15px;
        margin-top: 15px;
        line-height: 20px;
        font-weight: bold;
      }
      .workstyle-info-1{
        width: 100px;
      }
      .workstyle-icon{
        width: 33px;
        height: 33px;
      }
      .workstyle-blok-1{
        right: 30px;
      }
    }
    
    @media screen and (max-width:414px) {
      .workstyle {
        margin-left: 45px;
    }
    }

    @media screen and (max-width:393px) {
      .workstyle {
        margin-left: 35px;
    }
    }
    @media screen and (max-width:390px) {
      .workstyle {
        margin-left: 34px;
    }
    }


    @media screen and (max-width:380px) {

    .workstyle-blok-1{
      right: 30px;
    }
  }

  @media screen and (max-width:375px) {
    .workstyle-blok {
      margin-left: -5px;
  }
  }


  @media screen and (max-width:360px) {
    .workstyle{
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 38px;
      
        
    }

    .workstyle-info{
      font-size: 12px;
      margin-top: 15px;
      line-height: 20px;
    }
    .workstyle-blok-1{
      right: 50px;
    }
  }


  @media screen and (max-width:345px) {

    .workstyle-blok-1{
      right: 60px;
    }
    .workstyle{
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 10px;
      
        
    
    }
    
  
  }

  @media screen and (max-width:320px) {
    .workstyle {margin-left: 25px;}
    
  }