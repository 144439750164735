.scrollTop{
    width: 100%;
}
.scroll_btn{
   position: fixed; 
   bottom: 30px;
   right:  20px;   
  border: 1px solid gray;
   border-radius: 50%;
   font-size: 2rem;
   z-index: 10;
   cursor: pointer;
   color: #fff;
   background: #1e4078;
   transition: 0.5s ease;
}
.scroll_btn:hover{
   opacity: 0.9;
}

@media screen and (max-width: 406px) {
   .scroll_btn{
      right: 15px !important;
   }
}