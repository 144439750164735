@font-face {
  font-family: websoft_text;
  src: url(../../WebSoft_text.otf);
}
@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}


.services {
 
  display: flex;
  gap: 40px;
  justify-content: center;
  position: relative;
  /* background-color: rgb(146, 146, 135); */
  background: linear-gradient(
    90deg,
    #1c4787 -2.41%,
    #429eb9 46%,
    #1b7f9d 85.62%,
    #1b7f9d 80.4%,
    #1b7f9d 96.28%
  );
}
.serBox{
  max-width: 1440px;
  padding: 80px;
  display: flex;
    gap: 20px;
    justify-content: space-between;
    
}
.icons {
  width: 30px;
  height: 30px;
  background-color: #4b6693;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.icons:hover {
  background-color: #1f4179;
}
.box {
  z-index: 1;
  width: 400px;
  height: 420px;
  border: 0px solid white;
  background-color: white;
  overflow: hidden;
  transition: 0.3s;
}


.box-1 {
  width: 400px;
  height: 420px;
  border: 0px solid white;
  background-color: white;
  overflow: hidden;
  /* transition: 0.9s; */

}


.box-2 {
  width: 400px;
  height: 420px;
  border: 0px solid white;
  background-color: white;
  overflow: hidden;
  /* transition: 0.9s; */
}

.div-none {
  display: none;
  width: 400px;
  height: 420px;
  background: linear-gradient(90deg, #1d3f77, #2b6491, #429eb9);
  overflow: hidden;
  transition: 1.5s;
  z-index: 1;
  position: relative;
}

.services-title {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.title-h2 {
  /* height: 58px; */
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #1c4787;
  font-family: websoft_title;

}

.box h3 {
  margin-top: 60px;
  text-align: center;
  color: #1e4078;
  font-size: 36px;
  /* transition: 0.4s; */
  font-style: normal;
  font-weight: 400;
  font-family: websoft_title;
}

.ul {
  margin-top: 40px;
  /* align-items: center; */
}

.ul .li {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  list-style-type: none;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 26px;
  margin-top: 10px;
  /* color: #100F0F; */
  font-family: websoft_text;
  cursor: pointer;
}


.ul .li::before {
  content: "•";
  color: #1e4078;
  padding-right: 10px;
  padding-bottom: 16px;
  font-size: 100px;
 /* align-items: center; */
}

.box:hover  {
  color: white;
  /* background: linear-gradient(90deg, #1d3f77, #2b6491, #429eb9); */

}

.box:hover h3 {
  color: white;
}
.box:hover{
  background: linear-gradient(90deg, #1d3f77, #2b6491, #429eb9);
}

.box:hover li::before {
  color: white;
  content: "•";
}
.box:hover li {
  color: white;
}

.div-none .p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  list-style-type: none;
  display: flex;
  color: #fff;
  align-items: center;
  margin-top: 20px;
  font-family: websoft_text;
}

.info-blok {
  width: 400px;
  height: 420px;
  overflow: hidden;
  display: flex;
  /* justify-content: center;*/
  align-items: center; 
  flex-direction: column;
  padding: 30px;
}
.info-blok h3{
  color: #fff;
  font-size: 36px;
  font-weight: 400;
  font-family: websoft_title;
  margin-top: 30px;
}
.servic-left-line {
  width: 300px;
  height: 300px;
  border-bottom: 15px solid #1e4078;
  border-right: 15px solid #1e4078;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.servic-top-line {
  width: 300px;
  height: 300px;
  border-top: 15px solid #1e4078;
  border-left: 15px solid #1e4078;
  position: absolute;
  left: 30px;
  top: 30px;
}
@media screen and (max-width: 1350px) {
  .box,.box-1,.box-2{
    width: 350px;
  }
  .info-blok {
    width: 350px;}
    .div-none{
      width: 350px;
    }
}
@media screen and (max-width: 1200px) {
  .box h3 {
    font-size: 22px;
  }
  .ul .li {
    font-size: 18px;
  }
  .box,.box-1,.box-2{
    width: 280px;
  }
  .info-blok {
    width: 280px;}
    .div-none{
      width: 280px;
    }
}


@media screen and (max-width: 1024px) {

  .box, .box-1, .box-2 {
    width: 290px;
  }

  .servic-top-line {
    width: 280px;
    height: 280px;
    left: 15px;
    top: 15px;
}
.servic-left-line {
  width: 280px;
  height: 280px;
  right: 15px;
  bottom: 15px;
}

.ul .li {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-left: 10px;
  margin-top: 10px;
  
}

.ul .li::before {
  content: "•";
  color: #1e4078;
  padding-right: 4px;
  padding-bottom: 25px;
  font-size: 100px;
}
.info-blok h3 {
  font-size: 25px;
  margin-top: 0px;
}
.div-none .p {
  font-size: 14px;
  line-height: 23px;
  margin-top: 10px;
}
.box h3 {font-size: 25px;}

.serBox {
  padding: 65px;
  gap: 23px;
}

.servic-top-line {top: 25px;}
.servic-left-line {bottom: 25px;}

}


@media screen and (max-width: 950px) {
  .servic-top-line {
    top: 33px;
    left: 130px;
  }
  .servic-left-line {
    bottom: 35px;
    right: 130px;
  }
  .box {
    width: 450px;
    height: 380px;
  }
 
  .info-blok {
    width: 450px;}
    .div-none{
      width: 450px;
    }
  .services {
    
    margin-bottom: 5px;
  }
  .serBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 74px;
  }
  .box h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
  }
  .ul .li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .title-h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    /* margin-bottom: 80px; */
  }
  .services-title {
    height: 100px;
    /* margin-top: 40px;
    margin-bottom: 50px; */
  }
}

@media screen and (max-width: 770px) {
  .servic-top-line {
    top: 35px;
    left: 35px;
  }
  .servic-left-line {
    bottom: 35px;
    right: 35px;
  }
  /* .box {
    width: 400px;
    height: 380px;
  } */
}


@media screen and (max-width: 768px) {
    .box {
      width: 520px;
      height: 385px;
  }
  .box h3 {font-size: 31px;}
  .ul .li {font-size: 22px;}

  .ul .li {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 46px;
    margin-top: 8px;
}

.div-none {
  width: 520px;
}
.div-none .p {
  font-size: 19px;
  line-height: 27px;
  margin-top: 10px;
}
.info-blok h3 {
  font-size: 30px;
  margin-top: 2px;
}


}



@media screen and (max-width: 670px) {
  .servic-top-line {
    top: 35px;
    left: 40px;
  }
  .servic-left-line {
    bottom: 35px;
    right: 40px;
  }
  .serBox{
    padding: 56px;
  }
}
@media screen and (max-width: 570px) {
  .services{
    width: 92%;
    margin: auto;
  }
  .serBox {padding: 38px;}
  .serBox{gap: 10px;}
  .services-title {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }
  .box {
    width: 283px;
    height: 180px;
   
  }

  .services-title {
    height: 65px;
}

  .info-blok {
    width: 283px;}
    .div-none{
      width: 283px;
    }
  .box h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 15px;
  }
  .ul .li {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 3px;
    /* margin: 5px; */
    margin: none;
    color: #100F0F;
    /* align-items: center; */
  }

  .ul {
    margin-top: 15px;
    margin-bottom: 1px;
    /* align-items: center; */
  }
  .ul .li::before {
    font-size: 60px;
  }
  .ul .li::before {
    content: "•";
    color: #1e4078;
    padding-right: 10px;
    padding-bottom: 10px;
  
}
  .servic-top-line {
    width: 130px;
    height: 130px;
    border-top: 6px solid #1e4078;
    border-left: 6px solid #1e4078;
    top: 27px;
    left: 100px;
  }
  .servic-left-line {
    width: 130px;
    height: 130px;
    border-bottom: 6px solid #1e4078;
    border-right: 6px solid #1e4078;
    bottom: 27px;
    right: 100px;
  }
  .title-h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 0px;
    margin: auto;
}
  .info-blok h3{
    font-size: 20px;
    margin-top: 0px;
  }
  .div-none .p {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    list-style-type: none;
    display: flex;
    color: #fff;
    align-items: center;
    margin-top: 10px;
    font-family: websoft_text;
}
.info-blok{height: auto;}
.div-none{height: 280px;}


}
@media screen and (max-width: 470px) {
  .servic-top-line {
    top: 35px;
    left: 50px;
  }
  .servic-left-line {
    bottom: 35px;
    right: 50px;
  }
}
@media screen and (max-width: 520px) {
  .servic-top-line {
    top: 20px;
    left: 30px;
  }
  .servic-left-line {
    bottom: 20px;
    right: 30px;
  }
}

@media screen and (max-width: 425px) {
  .serBox {padding: 40px;}
  .ul .li {
    margin-left: 20px;
    /* margin-top: 11px; */
    margin-top: 18px;
  }

  .servic-left-line {
    width: 30%;
    height: 19%;
    bottom: 15px;
    right: 25px;
}

.servic-top-line {
  top: 15px;
  left: 20px;
  width: 30%;
  height: 20%;
}


}



@media screen and (max-width: 414px) {
  .servic-top-line {
    top: 16px;
    left: 16px;
  }
  .servic-left-line {
    bottom: 16px;
    right: 16px;
  }
}

@media screen and (max-width: 404px) {
  .servic-top-line {
    top: 11px;
    left: 11px;
  }
  .servic-left-line {
    bottom: 11px;
    right: 11px;
  }
}


@media screen and (max-width: 375px) {
  .serBox {padding: 30px;}
  .ul .li {
    margin-left: 20px;
    margin-top: 11px;
  }

}


@media screen and (max-width: 360px) {
  .box h3 {
    font-size: 18px;
  }
  .box {
    width: 260px;
    height: 160px;
  }
  .servic-top-line {
    top: 30px;
    left: 5px;
  }
  .servic-left-line {
    bottom: 40px;
    right: 5px;
  }
  .servic-top-line {
    width: 25%;
    height: 15%;
    top: 13px;
    left: 13px;
  }
  .servic-left-line {
    width: 25%;
    height: 14%;
    bottom: 10px;
    right: 14px;
  }
  .div-none {
    width: 260px;
}

}



@media screen and (max-width: 320px) {
  .serBox {padding: 20px;}
  .servic-top-line {
    width: 26%;
    height: 15%;
    top: 8px;
    left: 5px;
  }
  .servic-left-line {
    width: 26%;
    height: 15%;
    bottom: 8px;
    right: 5px;
  }
}