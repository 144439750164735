@font-face {
  font-family: websoft_title;
  src: url(../../WebSoft.TTF);
}
.feed_all {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 160px;
  margin-top: 100px;
}

.feed_all .h3 {
  text-align: center;
  color: #1e4078;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  font-family: websoft_title;
  margin-bottom: 90px;
}

.feedback {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;
  max-width:  1190px;
  width: 100%;
  margin: 30px auto;
  margin-top: 0;
}

.feedback .feed_image {
  margin-left: 50px;
  margin-top: 50px;
  width: 530px;
  /* height: 500px; */
}
.feedback .feed_image img {
  width: 537px;
  height: 500px;
}
.dnonebtn{
  display: none;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact .contact_inputs {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.contact .contact_inputs .input {
  font-size: 20px;
  border: none;
  border-bottom: 2px solid #1e4078;
  outline: none;
  padding: 10px;
  width: 350px;
  border-radius: 2px;
}

.contact .contact_inputs .input::-moz-placeholder {
  color: #100f0f;
}

.contact .contact_inputs .input::placeholder {
  color: #100f0f;
}

.contact .feed_btn {
  padding: 100px;
}

.contact .feed_btn button {
  background-color: #1e4078;
  color: white;
  border: none;
  width: 210px;
  height: 54px;
  font-size: 18px;
}

.left-line {
  width: 300px;
  height: 300px;
  border-top: 15px solid #1e4078;
  border-left: 15px solid #1e4078;
  position: absolute;
  /* transform: translate(142px, 135px); */
}

.right-line {
  width: 300px;
  height: 300px;
  border-bottom: 15px solid #1e4078;
  border-right: 15px solid #1e4078;
  position: absolute;
  transform: translate(350px, 300px);
}
.input_btn {
  width: 210px;
  height: 54px;
  border: none;
  background-color: #1e4078;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 100px;
  border-radius: 4px;
}
.input_btn:hover {
  background: linear-gradient(
    90deg,
    #1c4787 -2.41%,
    #429eb9 46%,
    #1b7f9d 85.62%,
    #1b7f9d 80.4%,
    #1b7f9d 96.28%
  );
}
@media screen and (max-width: 1220px) {

  .feedback{
    padding-left: 30px;
    padding-right: 30px;
  }
  .contact{
    width: 100%;
  }
  .feedback .feed_image {
    margin-left: 50px;
  }

  .contact .contact_inputs .input {
    width: 260px;
  }

  .input_btn {
    margin-top: 70px;
  }
  .feed_all .h3{
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1085px) {
  .feed_all{
    margin-top: 80px;
  }
  .feedback .feed_image img {
    /* margin-left: 100px; */
    width: 400px;
    height: 370px;
  }
 
  .right-line{
    transform: translate(210px, 180px);
  }
  .input_btn {
    margin-top: 70px;
  }

}
@media screen and (max-width: 995px) {
  .left-line {
    border-top: 10px solid #1e4078;
    border-left: 10px solid #1e4078;
   
  }
  .right-line {
   
    border-bottom: 10px solid #1e4078;
    border-right: 10px solid #1e4078;
  }
  .feedback .feed_image img {
  
    width: 450px;
    height: 420px;
  }
  .feedback .feed_image {
    margin-left: 30px;
  }
 
  .input_btn {
    margin-top: 70px;
  }
}
@media screen and (max-width: 995px) {
  .feedback .feed_image img {
   
    width: 370px;
    height: 340px;
  }
  .right-line {
    transform: translate(152px, 134px)
  }
 
  .input_btn {
    margin-top: 60px;
  }
  .feed_all {
    margin: 0;
  }
}
@media screen and (max-width: 830px) {
  .feed_all{
    margin-top: 60px;
  }
  .feedback .feed_image img {
    width: 300px;
    height: 270px;
  }
  .feedback .feed_image {
    margin-left: 30px;
  }
  .left-line {
    width: 250px;
    height: 250px;
  }
  .right-line {
    transform: translate(120px, 112px);
    width: 250px;
    height: 250px;
  }
 
  .input_btn {
    margin-top: 50px;
  }
  .feed_all {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .feedback .feed_image img {
    width: 264px;
    height: 250px;
  }
  .feedback .feed_image {
    margin-left: 30px;
    margin-top: 30px;
  }
  .left-line {
    width: 200px;
    height: 200px;
   
  }
  .right-line {
    transform: translate(130px, 110px);
    width: 200px;
    height: 200px;
  }

  .feed_all .h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 25px;
  }
  .input_btn {
    margin-top: 40px;
  }
  .feed_all {
    margin: 0;
  }
}
@media screen and (max-width: 688px) {
  .left-line {
    width: 120px;
    height: 120px;
  }
  .right-line {
    transform: translate(140px, 121px);
    width: 120px;
    height: 120px;
  }
  .feedback .feed_image img {
    width: 200px;
    height: 180px;
  }
 
  .contact .contact_inputs {
    gap: 30px;
  }
  .input_btn {
    margin-top: 40px;
    width: 150px;
  }

}
@media screen and (max-width: 576px) {
  .feedback {
    padding-left: 15px;
    padding-right: 15px;}
  .feed_all{
    margin-top: 20px;
  }
  .feedback .feed_image img {
    width: 135px;
    height: 140px;
  }
  .feedback .feed_image {
    margin-left: 15px;
    margin-top: 15px;
  }
  .left-line {
    width: 60px;
    height: 60px;
    border-top: 5px solid #1e4078;
    border-left: 5px solid #1e4078;
    

  }
  .right-line {
    transform: translate(109px, 107px);
    border-bottom: 5px solid #1e4078;
    border-right: 5px solid #1e4078;
    width: 60px;
    height: 60px;
  }

  .contact .contact_inputs .input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
 
  .contact .contact_inputs .input {
    width: 155px;
  }
  .contact .contact_inputs {
    gap: 0px;
  }
  .contact .contact_inputs .input {
    padding: 8px;
  }
  .input_btn {
    width: 100px;
    height: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    border-radius: 4px;
    margin: 20px;
    margin-right: 50px;
  }
  .dblockbtn{
   top: 70%;
   position: absolute;
   left: 60%;
  }
  .dnonebtn{
    display: block;
    visibility: hidden;
  }
  .feed_all .h3{
    font-size: 22px;
  }
}
@media screen and (max-width: 395px) {
  .right-line {
    transform: translate(96px, 98px);
  }
 
  .feedback .feed_image {
    margin-left: 10px;
    margin-top: 10px;
  }

  .contact .contact_inputs .input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
  }
  .contact .contact_inputs .input {
    width: 130px;
  }
  .feed_all .h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }
}


@media screen and (max-width: 340px){
.feedback .feed_image img {
    width: 126px;
    height: 120px;
  }
  .right-line {
    transform: translate(90px, 85px);
}
.contact{
  margin-top: -20px;
}

}
