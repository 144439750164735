.swiper {
    width: 100%;
    height: 100%;
  } 
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(1);

  }
  .swiper-slide-active{
    opacity: 1;
    filter: grayscale(0);
  }
  .swiper-slide img {
    display: block;
    object-fit: contain;
    
  }
  
  .img-0{
  
      width: 100%;
      height:100% ;
      object-fit: contain;
  
  }
  .img-1{
    height: 155px !important;
    width: 150px !important;


  }
  .img-2{
    height: 60px !important;
    width: 190px !important;

  }
  .img-3{
    height: 155px !important;
    width: 150px !important;
  }
  .img-4{
    height: 55px !important;
    width: 150px !important;
    

  }
  
  .swiper-container {
    width:300px;
  }
  @media screen and (min-width: 300px) {
    .swiper-container {
      width: 300px;
    }
    .img-0{
      height: 39px !important;
      width: 62px !important;
    
    }
    .img-1{
      height: 63px !important;
      width: 60px !important;
  
  
    }
    .img-2{
      height: 18px !important;
      width: 60px !important;
  
    }
    .img-3{
      height: 60px !important;
      width: 60px !important;
    }
    .img-4{
      height: 10px !important;
      width: 60px !important;
      
  
    }
    .h3-coll{
      font-size: 22px;
    }
  }

  @media screen and (min-width: 600px) {
    .swiper-container {
      width: 600px;
    }
    .img-0{
      height: 50px !important;
      width: 80px !important;
   
    }
    .img-1{
      height: 84px !important;
      width: 80px !important;
  
  
    }
    .img-2{
      height: 24px !important;
      width: 80px !important;
  
    }
    .img-3{
      height: 82px !important;
      width: 80px !important;
    }
    .img-4{
      height: 15px !important;
      width: 80px !important;
      
  
    }
    .h3-coll{
      font-size: 32px;
      margin-top: 20px;
    }
  }
  
  @media screen and (min-width: 800px) {
    .swiper-container {
      width: 800px;
    }
    .img-0{
      height: 79px !important;
      width: 125px !important;
   
    }
    .img-1{
      height: 104px !important;
      width: 100px !important;
  
  
    }
    .img-2{
      height: 42px !important;
      width: 138px !important;
  
    }
    .img-3{
      height: 71px !important;
      width: 107px !important;
  }
    
    .img-4{
      height: 32px !important;
    width: 170px !important;
      
  
    }
    .h3-coll{
      margin-top: 60px;
    }
   
  }
  
  
  
  @media screen and (min-width:1040px) {
    .swiper-container {
      width: 1040px;
    }
    .img-0{
      height: 95px !important;
      width: 150px !important;
    
    }
    .img-1{
      height: 155px !important;
      width: 150px !important;
  
  
    }
    .img-2{
      height: 60px !important;
      width: 190px !important;
  
    }
    .img-3{
      height: 155px !important;
      width: 150px !important;
    }
    .img-4{
      height: 29px !important;
      width: 150px !important;  
    }
    .h3-coll{
      font-size: 48px;
    }
  }
  